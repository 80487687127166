import styled, { ThemeProvider } from "styled-components";
// import { ImportImages } from "../assets/Images/exportImages";
import { darkThemeStyled, lightThemeStyled } from "../assets/theme";
import { ImportImages } from "../assets/Images/exportImages";
// import { Images } from "../assets/Images/_images";

export const Page = styled.div`
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    display: flex;
    flex: 1;
    // height: 100vh;
    flex-wrap: wrap;
    flex-direction: column;
`;


export const PageSize = styled.div`
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

export const Content = styled.div`
    color: ${(props) => props.theme.text};
    display: flex;
    flex: 1;
    flex-direction: column;
    // margin-top:60px;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.text};
`;

export const MinTitle = styled.h3`
  color: ${(props) => props.theme.text};
  margin:20px
`;

export const Text = styled.h4`
  color: ${(props) => props.theme.text};
  font-size: clamp(9px, 2.5vw, 14px);
  padding: 1px;
  margin: 1px;
`;

export const Button = styled.button`
    background-color: ${(props) => props.theme.buttonBg};
    color: ${(props) => props.theme.buttonText};
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
`;

export const Card = styled.div`
  background-color:${(props) => props.theme.card};
  background: linear-gradient(90deg,${(props) => props.theme.buttonBg},${(props) => props.theme.card});  
  display:flex;
  flex-direction: column;
  min-width: 100px;
  min-height: 100px;
  border-radius:15px;
  border: 2px solid #000;
  box-shadow: 24;
  overflow-y: auto;
  max-height: 100vh;
  box-shadow: 0 4px 4px rgba(105, 105, 105, 0.3);
  &:hover {
    box-shadow: 0 8px 8px rgba(105, 105, 105, 0.3);
    background: linear-gradient(180deg,${(props) => props.theme.buttonBg},${(props) => props.theme.card});
    }
`;

export const Logo = ({ h, w }) => (
  <img alt="code-zone" className='logo' style={{ height: h, width: w, }} src={ImportImages("logo")} />
)

export const StyledProvider = ({ children, theme }) => (
  <ThemeProvider theme={theme ? darkThemeStyled : lightThemeStyled}>
    {children}
  </ThemeProvider>
)