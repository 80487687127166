const ShiftData = {
    worker: {
        eng: "Worker",
        arabic: "العامل",
        hebrew: "עובד"
    },
    checkIn: {
        eng: "Check In",
        arabic: "تسجيل الدخول",
        hebrew: "צ'ק אין"
    },
    checkOut: {
        eng: "Check Out",
        arabic: "تسجيل الخروج",
        hebrew: "צ'ק אאוט"
    },
    customer: {
        eng: "Customer",
        arabic: "الزبون",
        hebrew: "לקוח"
    },
    project: {
        eng: "Project",
        arabic: "المشروع",
        hebrew: "פרויקט"
    },
    created: {
        eng: "Created",
        arabic: "تم الإنشاء",
        hebrew: "נוצר"
    },
    edited: {
        eng: "Edited",
        arabic: "تم التعديل",
        hebrew: "נערך"
    },
    duration: {
        eng: "Duration",
        arabic: "المدة",
        hebrew: "משך"
    },
    zoomShift: {
        eng: "Zoom Shift",
        arabic: "Zoom Shift",
        hebrew: "Zoom Shift",
    },
}

export const Alerts = {
    Navigation: {
        eng: "Navigation in progress...",
        arabic: "التنقل قيد التقدم...",
        hebrew: "ניווט בתהליך..."
    },
    timeNotValid: {
        eng: "Time not valid",
        arabic: "الوقت غير صالح",
        hebrew: "הזמן לא חוקי"
    },
    RequiredCP: {
        eng: "Required customer and project",
        arabic: "مطلوب العميل والمشروع",
        hebrew: "נדרש לקוח ופרויקט"
    },
    validDate: {
        eng: "Pick a valid date",
        arabic: "اختر تاريخًا صالحًا",
        hebrew: "בחר תאריך תקין"
    },
    logIn_requirement: {
        eng: "Please enter both phone number and password.",
        arabic: "يرجى إدخال رقم الهاتف وكلمة المرور.",
        hebrew: "אנא הזן גם מספר טלפון וגם סיסמה."
    },

    logInFailed: {
        eng: "Some inputs are wrong.",
        arabic: "بعض المدخلات غير صحيحة.",
        hebrew: "חלק מהקלטים שגויים."
    },
    Test: {
        eng: "today",
        arabic: "",
        hebrew: ""
    },
}

const STRINGS = {
    create: {
        eng: "Create",
        arabic: "انشئ",
        hebrew: "צור חשבון"
    },
    creating: {
        eng: "creating",
        arabic: "قيد الانشاء",
        hebrew: "תוך יצירה"
    },
    add_project: {
        eng: "Add Project",
        arabic: "إضافة مشروع",
        hebrew: "הוסף פרויקט"
    },
    is_admin: {
        eng: "Is Admin",
        arabic: "هو مشرف",
        hebrew: "האם מנהל"
    },
    Duration: {
        eng: "Duration",
        arabic: "المدة الزمنية",
        hebrew: "משך"
    },
    zoom: {
        eng: "Zoom Shift",
        arabic: "تحول التكبير",
        hebrew: "שינוי זום"
    },
    Done: {
        eng: "Done",
        arabic: "فعله",
        hebrew: "בוצע"
    },
    start: {
        eng: "Start",
        arabic: "بداية",
        hebrew: "התחלה"
    },
    end: {
        eng: "End",
        arabic: "نهاية",
        hebrew: "סיום"
    },
    customers: {
        eng: "Customers",
        arabic: "العملاء",
        hebrew: "לקוחות"
    },
    projects: {
        eng: "Projects",
        arabic: "المشاريع",
        hebrew: "פרויקטים"
    },
    StartShift: {
        eng: "Start Shift",
        arabic: "بدء الوردية",
        hebrew: "התחלת המשמרת"
    },
    EndShift: {
        eng: "End Shift",
        arabic: "انهاء الوردية",
        hebrew: "סיום המשמרת"
    },
    today: {
        eng: "Today",
        arabic: "اليوم",
        hebrew: "היום"
    },
    edit: {
        eng: "Edit",
        arabic: "تعديل",
        hebrew: "ערוך"
    },
    SelectDate: {
        eng: "Select date",
        arabic: "اختر التاريخ",
        hebrew: "בחר תאריך"
    },
    Month: {
        eng: "Month",
        arabic: "الشهر",
        hebrew: "חודש"
    },
    phone: {
        eng: "phoneNumber",
        arabic: "رقم الهاتف",
        hebrew: "מספר טלפון"
    },
    pass: {
        eng: "password",
        arabic: "كلمة المرور",
        hebrew: "סיסמה"
    },
    LOG_IN: {
        eng: "LOG-IN",
        arabic: "تسجيل الدخول",
        hebrew: "כניסה"
    },
    Log_out: {
        eng: "Log out",
        arabic: "تسجيل الخروج",
        hebrew: "התנתקות"
    },
    welcome: {
        eng: "welcome",
        arabic: "أهلاً وسهلاً",
        hebrew: "ברוך הבא"
    },
    name: {
        eng: "name",
        arabic: "الاسم",
        hebrew: "שם"
    },
    Loading: {
        eng: "Loading...",
        arabic: "جار التحميل...",
        hebrew: "טוען..."
    },
    userWorkingHour: {
        eng: "user working Hour",
        arabic: "ساعات عمل المستخدم",
        hebrew: "שעות עבודת המשתמש"
    },
    customerInfo: {
        eng: "customer info",
        arabic: "معلومات العميل",
        hebrew: "פרטי לקוח"
    },
    Save: {
        eng: "Save",
        arabic: "حفظ",
        hebrew: "שמירה"
    },
    saving: {
        eng: "saving...",
        arabic: "جار الحفظ...",
        hebrew: "שומר..."
    },
    Edit: {
        eng: "Edit",
        arabic: "تعديل",
        hebrew: "עריכה"
    },
    cancelEdit: {
        eng: "cancel Edit",
        arabic: "إلغاء التحرير",
        hebrew: "ביטול עריכה"
    },
    success: {
        eng: "Success",
        arabic: "نجاح",
        hebrew: "הצלחה"
    },
    failed: {
        eng: "Failed",
        arabic: "فشل",
        hebrew: "נכשל"
    },
    budget: {
        eng: "budget",
        arabic: "ميزانية",
        hebrew: "תקציב"
    },
    HourPrice: {
        eng: "Hour Price",
        arabic: "سعر الساعة",
        hebrew: "מחיר לשעה"
    },
    deadLine: {
        eng: "Deadline",
        arabic: "الموعد النهائي",
        hebrew: "מועד הסיום"
    },
    add_project_for_Existing_customer: {
        eng: "Add project for Existing customer",
        arabic: "إضافة مشروع لعميل موجود",
        hebrew: "הוסף פרויקט עבור לקוח קיים"
    },
    select_customer_to_add_a_projects: {
        eng: "Select customer to add projects",
        arabic: "اختر العميل لإضافة مشاريع",
        hebrew: "בחר לקוח להוספת פרויקטים"
    },
    are_you_sure: {
        eng: "Are you sure?",
        arabic: "هل أنت متأكد؟",
        hebrew: "האם אתה בטוח?"
    },
    phoneNumber_ist_exist: {
        eng: "Phone number doesn't exist",
        arabic: "رقم الهاتف غير موجود",
        hebrew: "מספר הטלפון אינו קיים"
    },
    WorkingHour: {
        eng: "working Hour",
        arabic: "ساعات العمل",
        hebrew: "שעות עבודה"
    },
    user: {
        eng: "user",
        arabic: "مستخدم",
        hebrew: "משתמש"
    },
    TotalHours: {
        eng: "Total Hours",
        arabic: "إجمالي الساعات",
        hebrew: "סך השעות"
    },
    sign_up: {
        eng: "Sign Up",
        arabic: "انشاء حساب",
        hebrew: "הירשם"
    },
    SIGN_UP: {
        eng: "Sign Up",
        arabic: "انشاء حساب",
        hebrew: "הירשם"
    },
    Company_Name: {
        eng: "Company Name",
        arabic: "اسم الشركة",
        hebrew: "שם החברה"
    },
    info: {
        eng: "info",
        arabic: "معلومات",
        hebrew: "מידע"
    },
    manager: {
        eng: "manager",
        arabic: "مدير",
        hebrew: "מנהל"
    },
    Company_Id: {
        eng: "Company Id",
        arabic: "هوية الشركة",
        hebrew: "מזהה החברה",
    },
    Test: {
        eng: "today",
        arabic: "",
        hebrew: ""
    },
    status:{
        eng: "status",
        arabic: "حالة",
        hebrew: "סטטוס"
    },
    level:{
        eng: "level",
        arabic: "مستوى",
        hebrew: "רמת"
    },
    Filter: {
        eng: "Filter",
        arabic: "فلتر",
        hebrew: "סינון"
    },
    ShiftData: ShiftData,
};

export const getTXT = (type, Language, Collection) => {
    return (
        Collection ?
            STRINGS[Collection]?.[type]?.[Language]
            : STRINGS[type][Language] || "err"
    )
}



export default STRINGS;