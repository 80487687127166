import {Icon,Tab,Tabs} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import TheContext from '../Storage/theContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserFromStorage } from '../Storage/restoreData';
import {TabsLabels } from '../assets/globalValues';
import { TheTheme } from '../assets/theme';
import MenuLanguage from './MenuLanguage';

const SidebarContent = () => {
    const {pathValue,setPathValue,Language,} = useContext(TheContext);
    const navigate = useNavigate();
    const locate = useLocation();
    const user = {...getUserFromStorage()};
      

    const handleChange = (event, newValue) => {
        setPathValue(newValue);
    };

    const RenderSideBarTab=()=>{
      const isWorker=user?._level==="worker"&&"worker";
      const isAdmin=user?._isAdmin&&"admin";
      const isCustomer=user?._level==="customer"&&"customer";

      const handelOptionalTabs =(type)=>{
        return (
          (type==='')||
          (type===isWorker)||
          (type===isAdmin)||
          (type===isCustomer)
        )
      }

      const Labels =({name,IconName})=>{
        return(
          <>
          <Icon>
            <IconName/>
          </Icon>
          {name}
          </>
        )
      }

        return(
          TabsLabels.map((tabsLabel,i)=>
          handelOptionalTabs(tabsLabel.required)?
            <Tab
                key={i}
                label={<Labels name={tabsLabel.name[Language]} IconName={tabsLabel.IconName}/>} 
                value={tabsLabel.path} 
                onClick={()=>{navigate(tabsLabel.path,{unstable_viewTransition:true,})}}
            />
            :null
          )
        )
    }//end of RenderSideBarTab

    useEffect(() => {
      console.log(user);
        const path = locate.pathname.split('/')?.[2];
        setPathValue(path||'')
      }, [locate.pathname]);

    return (
      <div style={style.coverTab}>
        <Tabs 
          orientation="vertical"
          variant="scrollable"
          value={pathValue}
          onChange={handleChange}
        >
          {RenderSideBarTab()}
        </Tabs>
        <MenuLanguage/>
        </div>
    );
}

export default SidebarContent;

const style={
  coverTab:{
    height: "100vh",
    backgroundColor:TheTheme.main,
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    padding:'2px'
  }
}
