import { Button } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const UnAvailable = () => {
    const navigate = useNavigate();
    return (
        <div style={{padding:'40px'}}>
          <Link unstable_viewTransition replace={true}>
            <Button color="error" onClick={() => {
              navigate("/",{replace:true})
              }}>
              {"! page not available !"}
            </Button>
          </Link>
        </div>
    );
}

export default UnAvailable;
