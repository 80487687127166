import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import TheContext from '../../../Storage/theContext';
import STRINGS, { Alerts } from '../../../assets/Strings';
import { newIntendedDate } from '../../../assets/handelTime';
import { toast } from 'react-toastify';
import Sounds from '../../../assets/sounds/_exportSounds';

const getMaxTime=(isStart,timePickEnd,nextShift,year,monthIndex,day)=>{
  const today = new Date();
  if (today.getDate()===day&&today.getMonth()===monthIndex) {
    console.log("max time",today.getHours(),today.getMinutes(),today.getSeconds(),today.getMilliseconds());
    return today;
  }
  if (isStart&&timePickEnd) {
    return new Date(timePickEnd);
  }
  if (nextShift?.checkIn) {
    return new Date(nextShift.checkIn);
  }
  else{
    return newIntendedDate(null,year,monthIndex,(day+1),0,0,0,0);
  }
}

const getMinTime=(isStart,timePickStart,backShift,year,monthIndex,day)=>{
    if ((!isStart)&&timePickStart) {
      return new Date(timePickStart)
    }
    if (backShift?.checkOut) {
    return new Date(backShift.checkOut)
  }
  else{
    return newIntendedDate(null,year,monthIndex,(day-1),23,59,59,999);
  }
}

const TheTimePicker = ({type,ShiftInfo,setShiftInfo,arrayOfShiftsInDay,shiftIndex,day,keyMonth}) => { //2999 code optimization 
  const {Language} = useContext(TheContext);
  localStorage.setItem("backUpShift",JSON.stringify(ShiftInfo));
  let START = STRINGS.start[Language];
  let END = STRINGS.end[Language];
  const length=arrayOfShiftsInDay?.length?arrayOfShiftsInDay?.length:0;
  const shift_Index = shiftIndex===-1?(length):shiftIndex
  const Shifts=arrayOfShiftsInDay?[...arrayOfShiftsInDay]:[]//if🔹its🔹not🔹array
  const [month, year] = keyMonth.split("/");
  const monthIndex = month-1;
  const timePick = ShiftInfo.timePick;//Start🔹End
  const existingTime = timePick[type];
  const backShift = Shifts?.[shift_Index-1];
  const nextShift = Shifts?.[shift_Index+1];
  const ShiftInfoState = ShiftInfo.timePick;
  const maxTimeDate = getMaxTime((type===START),timePick[END],nextShift,year,monthIndex,day)
  const minTimeDate = getMinTime((type===START),timePick[START],backShift,year,monthIndex,day)

  useEffect(() => {
    console.log("maxTimeDate",maxTimeDate.toLocaleString());
    console.log("minTimeDate",minTimeDate.toLocaleString());
  }, []);

  const setDefaultValue = () => {
    if ((type===START) && !existingTime) {
      const intendedDate = newIntendedDate(null,year,monthIndex,day);
      if (minTimeDate && shift_Index) {
        let gaol = intendedDate > minTimeDate ? intendedDate : minTimeDate;
        ShiftInfoState[START] = gaol
        return gaol
      } else {
        return existingTime ? new Date(existingTime) : new Date(intendedDate);
      }
    } else {
      return existingTime ? new Date(existingTime) : null;
    }
  };

  const TimeValidation =(chosenTime) => {
    let chosen_Time = new Date(chosenTime);
    let minimumDate = new Date(minTimeDate);
    let maximumDate = new Date(maxTimeDate);
    console.log(chosen_Time.toLocaleString());
    console.log(minimumDate.toLocaleString());
    console.log(maximumDate.toLocaleString());
    console.log("TimeValidation",chosen_Time >= minimumDate && chosen_Time <= maximumDate);
    return chosen_Time >= minimumDate && chosen_Time <= maximumDate;
  };

  const [time, setTime] = useState(setDefaultValue());

  const onAccept = (date) => {//2999
    const dateFormat = newIntendedDate(date,year,monthIndex,day);
    if (TimeValidation(dateFormat)) {
      setTime(dateFormat);
      ShiftInfoState[type] = dateFormat;
      setShiftInfo({ ...ShiftInfo });
    }else{
      const audio = new Audio(Sounds.error());
      audio.play();
      toast.error(Alerts.timeNotValid[Language]);
      let backUpShift=JSON.parse(localStorage.getItem("backUpShift"))
      setShiftInfo({ ...backUpShift });
    }
  };

  const onChange = (date) => {
    const dateFormat = newIntendedDate(date,year,monthIndex,day);
    if (TimeValidation(dateFormat)) {
      setTime(dateFormat);
      ShiftInfoState[type] = dateFormat;
    }else{
      const audio = new Audio(Sounds.error());
      audio.play();
      toast.error(Alerts.timeNotValid[Language]) 
    }
  };

  return (
    <div className='containerTimePicker'>
      {type}
      <MobileTimePicker
        className='TimePicker'
        defaultValue={dayjs(time)}
        ampm={false}
        autoFocus
        onAccept={(date) => onAccept(date.toDate())}
        onChange={(date) => onChange(date.toDate())}
      />
    </div>
  );
};

export default TheTimePicker;

/*map keys of received data
  {
  type: Start/End [language],
  ShiftInfo: { // object of the values Start/End //
    timePick: {
      Start: Current-time/end of the last shift,
      End: null 
    },
    ...//more data of the object
  },
  arrayOfShiftsInDay:all-shifts-in-this-day,
  shiftIndex: Selected shift index or -1 if new shift,
  day: Selected day,
  keyMonth
}
*/
