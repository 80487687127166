import React, { useState } from "react";
import TheContext from "./theContext";
import { isDarkTheme,setDarkTheme} from "./restoreData";


const getCurrentDate=()=>{
  const currentDate = new Date(); 
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  return {currentDate,currentDay,currentMonth,currentYear,}
}

let shiftDataFace={
  title:'',
  day:-1,
  ModalVisible:false,
  setModalVisible:()=>{},
  shiftIndex:-1,
  existingShift:{},
}

const TheProvider = (props) => {
  // localStorage.clear()
  const {currentDate,currentDay,currentMonth,currentYear,}=getCurrentDate();
  const [date, setDate] = useState(`${currentMonth}/${currentYear}`);//2999
  const DateStats = {
    currentDate,
    currentDay,
    currentMonth,
    currentYear,
    keyMonth: { date, setDate },
  };

  const [user, setUser] = useState();
  const [group, setGroup] = useState();
  const [allShifts, setAllShifts] = useState();
  const [shiftData, setShiftData] = useState(shiftDataFace);
  const [customers, setCustomers] = useState();
  const [pathValue, setPathValue] = useState("");
  const [isDark, setDark] = useState(isDarkTheme());
  // const [isMute, setMute] = useState(isMuted());
  const [Language, setLanguage] = useState(localStorage.getItem("Language")||"eng");
  const isWorker = user?._level==="worker"
  // const userWorkingHour = isWorker ? user?.level.worker?.workingHour : null;
  
  const [displayAlert, setDisplayAlert] = useState({
    play:false,
    severity:'success',
    message:'',
    time:1000,
  });

  const changeTheme=()=>{
    setDark(!isDark)
    setDarkTheme(!isDark)
  }

  return (
    <TheContext.Provider
      value={{
        user,setUser,
        group, setGroup,
        allShifts, setAllShifts,
        shiftData, setShiftData,
        isWorker,
        customers,
        setCustomers,
        pathValue,
        setPathValue,
        DateStats,
        displayAlert,
        setDisplayAlert,
        changeTheme,
        isDark,
        Language, 
        setLanguage,
      }}
    >
      {props.children}
    </TheContext.Provider>
  );
};

export default TheProvider;
