import React, { useContext } from "react";
import TheModal from "../../TheModal";
import TheContext from "../../../Storage/theContext";
import AddShiftDayBoxV2 from "./AddShiftDayBoxV2";

const AddShiftDayBoxModalV2 = () => {
  const {shiftData,setShiftData} = useContext(TheContext);
  const {title,day,ModalVisible,shiftIndex,existingShift,} = shiftData;

  const closeModal=(props)=>{
    console.log("closeModal");
    setShiftData({
      title:'',
      day:-1,
      ModalVisible:false,
      setModalVisible:()=>{},
      shiftIndex:-1,
      existingShift:{},
    })
  }

  return (
    <TheModal visible={ModalVisible} setVisible={closeModal} title={title}>
      <AddShiftDayBoxV2
        setModalVisible={(v)=>closeModal(v)}
        existingShift={existingShift}
        day={day}
        shiftIndex={shiftIndex}
      />
    </TheModal>
  );
};

export default AddShiftDayBoxModalV2;
