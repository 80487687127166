import React from 'react';
import {Box,IconButton,Modal,} from '@mui/material';
import {Close} from '@mui/icons-material';
import {Card,MinTitle,} from '../elements/MI-elements';

const TheModal = ({ title, visible, setVisible, children }) => {

  const closeModal=()=>{
    setVisible?.(false)
  }

  return (
    <Modal
      sx={style.Modal}
      open={visible}
      onClose={()=>closeModal()}
    >
        <Card>
          <div style={style.TopModal}>
              <MinTitle>
                {title||"title"}
              </MinTitle>
              <IconButton style={style.closeButton} onClick={()=>closeModal()}>
                <Close/>
              </IconButton>
          </div>
          

          <Box sx={style.inerBox}>
            {children}
          </Box>

        </Card>
    </Modal>
  );
};

export default TheModal;

const style = {
  Modal: {
    flex:1,
    justifyContent:'center',
    alignContent:'center',
    margin:"20px"
  },
  inerBox: {
    flex: 1,
    p: 1,
  },
  TopModal:{ 
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  closeButton:{
    borderRadius:"15px",
    margin:"10px"
  }
};
