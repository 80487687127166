import { GTranslateRounded } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { LanguageValues } from "../assets/globalValues";
import React, { useContext } from "react";
import TheContext from "../Storage/theContext";
import { TheTheme } from "../assets/theme";
import { useNavigate } from "react-router-dom";

const MenuLanguage=({style,className,toRefresh})=>{
    const {setLanguage} = useContext(TheContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let navigate=useNavigate()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLanguage = (type) => {
      setLanguage(type);
      localStorage.setItem("Language",type)// eslint-disable-next-line
      let x = toRefresh?navigate("/"):null
    };
  
    return (
      <div className={className} style={style||{backgroundColor:TheTheme.main,}}>
        <IconButton
          onClick={handleClick}
          style={{backgroundColor:"#156280",}}
        >
          <GTranslateRounded/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {LanguageValues.map(({name,type},i)=>(
            <MenuItem 
              onClick={()=>{handleLanguage(type)}}
              key={i} 
            >
                {name}
              </MenuItem>
          ))}
        </Menu>
      </div>
    );
}

  export default MenuLanguage;