import Lottie from "lottie-react";
import React from 'react';
import loadSticks from './load1.json'
import loading from './load2.json'
import success from './success.json'

const defaultStyle = {
  position: 'absolute',
  display: 'flex',
  alignSelf: 'center',
  zIndex: '0',
  opacity: '0.4',
  overflow: 'hidden',
};

const LottieAnimation = ({data,loop,style}) => {
    const animationData={
        loadSticks,
        loading,
        success,
    }
    return (
      <Lottie 
        animationData={animationData[data]||success}
        loop={loop||false}  
        style={style||defaultStyle}
      />
    );
}

export default LottieAnimation;
