import './BoxOfArrShiftsInDay.css';
import React, {useContext, useState} from 'react';
import {Button, CircularProgress,} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {shiftAUD_V2,} from '../../RES/user';
import TheContext from '../../Storage/theContext';
import {calculateDuration,} from '../../Storage/restoreData';
import moment from 'moment';
import ShiftDetails from './ShiftDetails';
import STRINGS, { Alerts } from '../../assets/Strings';
import { toast } from 'react-toastify';
import Sounds from '../../assets/sounds/_exportSounds';

const BoxOfArrShiftsInDay=({ArrayShiftInDay,day,AdminReq,disableStartShift})=>{
  const {user,DateStats,Language,setShiftData} = useContext(TheContext);
  const {keyMonth}=DateStats;
  const [isLoading, setIsLoading] = useState(false);
  const length  = ArrayShiftInDay?.length?ArrayShiftInDay?.length:0
  let StartShift=STRINGS.StartShift[Language];
  let EndShift=STRINGS.EndShift[Language];

  const GetLastShift=()=>{
    if (length > 0) {
      const lastShift = ArrayShiftInDay[length - 1];
      return lastShift
    }
  }

  const checkShiftState =()=>{//start end
    const lastShift = GetLastShift()
    if ((!length)||(lastShift&&lastShift.checkOut)) {
      return true
    }else{
      return false
    }
  }

  const DisplayStartOrEndShift =()=>{
    const currentDate = new Date()
    currentDate.setDate(day)
    const [checkOutDate, setCheckOutDate] = useState(currentDate);
    const state = checkShiftState()
    const shiftState=state?StartShift:EndShift;

    const onClick=()=>{
      state?
      setShiftData((v)=>({
        ...v,
        title:`${STRINGS.StartShift[Language]} ${day}`,
        day:day,
        ModalVisible:true,
        shiftIndex:-1,
        existingShift:false,
      }))
      :
        updateCheckOut(checkOutDate,setCheckOutDate);
    }

    const ShiftBTNAble=()=>{
      const currentDate=new Date();
      const [m,y]=keyMonth.date.split("/");
      const shiftDate=new Date(y,(m-1),day);
      return (shiftDate>=currentDate)
    }

    return(
      <>
        {!state?<EndShiftTimePick checkOutDate={checkOutDate} setCheckOutDate={setCheckOutDate}/>:null}
        <Button {...styles.addButton} 
          onClick={onClick} 
          disabled={ShiftBTNAble()}
        > 
          {shiftState}
        </Button>
      </>
    )
  }

  const EndShiftTimePick=({setCheckOutDate,checkOutDate})=>{
    const setDate=(date)=>{
      const dateFormat = new Date(date);
      dateFormat.setSeconds(0);
      dateFormat.setMilliseconds(0);
      setCheckOutDate(dateFormat)
    }
    return(
        <MobileTimePicker
          {...styles.addButton}
          defaultValue={dayjs(checkOutDate)}
          value={dayjs(checkOutDate)}
          ampm={false}
          autoFocus
          onAccept={(date) => {setDate(date)}}
        />
    )
  }

  const validateAndCalculateDuration=(startTime,endTime,setCheckOutDate)=>{
    const checkOutMoment = moment(endTime);
    const isBefore = checkOutMoment.isBefore(startTime);
    if (isBefore) {
      setCheckOutDate(startTime)
      return false;
    }else{
      return calculateDuration(startTime,endTime);
    }
  }

  const updateCheckOut=async (checkOutDate,setCheckOutDate)=>{
    setIsLoading(true)
    const lastShift = GetLastShift()
    console.log('lastShift',lastShift,"\n shift _id",lastShift._id,"\n id",lastShift.id);
    const duration=validateAndCalculateDuration(lastShift.checkIn,checkOutDate,setCheckOutDate)
    if (duration && (new Date(checkOutDate)<=new Date())) {
      lastShift.checkOut=checkOutDate;
      lastShift.duration=duration;
      toast.promise(
        shiftAUD_V2(user._id,lastShift,keyMonth,day,false),
        {
          pending:STRINGS.Loading[Language],
          success:`${STRINGS.success[Language]}✅` ,
          error: `${STRINGS.failed[Language]}❌`
        }
      ).then((v)=>{
        console.log("🕘",v);
        setIsLoading(false)
      }).catch((e)=>{
        console.log(e);
        toast.error(e.message,{autoClose: 1500,});
        setIsLoading(false)
      })
    }else{
      const audio = new Audio(Sounds.error());
      audio.play();
      toast.error(Alerts.timeNotValid[Language],{autoClose: 1500,});
      setIsLoading(false)
    }
  }
  
  const RenderBoxOfShifts =()=>{
    const LOADING={isLoading,setIsLoading,}
    return(
      ArrayShiftInDay&&ArrayShiftInDay?.length?
      ArrayShiftInDay.map((ShiftObj,i)=>
        <ShiftDetails 
          ArrayShiftInDay={ArrayShiftInDay} 
          LOADING={LOADING} 
          shift={ShiftObj} 
          shiftIndex={i} 
          day={day}
          key={i} 
          AdminReq={AdminReq}
        />        
      )
      :
      null
    )
  }

  return(
    <div className='BoxOfArrShiftsInDay'>
      <RenderBoxOfShifts/>
      {/* dlc 2999*/}      
      {AdminReq||disableStartShift?null:(!isLoading)?
      <DisplayStartOrEndShift/>:
      <CircularProgress sx={styles.load}/>}
    </div>
  )
}

export default BoxOfArrShiftsInDay

const styles = {
  addButton: {
      variant: 'contained',
      color: 'primary',
      size: 'small',
      sx:{width: 100,alignSelf:'flex-end',fontSize: '12px',}
  },
  load:{alignSelf:'center'}
};