import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import TheContext from '../../../Storage/theContext';
import { TabsLabels } from '../../../assets/globalValues';

const AdminBtn = TabsLabels.filter((item)=>item.required==="admin")

const AdminPromise=({isAdmin})=>{
  const {setPathValue,Language} = useContext(TheContext);
  
  const onClick=(value)=>{
    setPathValue(value)
  }

  if (isAdmin) {
    return(
      <div className='admin-promise'>
        {AdminBtn.map((btn,i)=>(
          <Link unstable_viewTransition key={i} to={`${btn.path}`}>
            <Button variant="outlined" sx={{margin:"5px"}} 
              onClick={()=>onClick(btn.path)}
            >
              {btn.name[Language]}
            </Button>
          </Link>
        ))}
      </div>
    )
  }
};

export default AdminPromise;
