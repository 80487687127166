import React, { useState, useRef, useContext, useEffect } from 'react';
import { Container, TextField, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TheContext from '../../../Storage/theContext';
import { getUserFromStorage, } from '../../../Storage/restoreData';
import { navigateMainRout } from '../../../assets/LocalFunctions/Navigation';
import STRINGS, { Alerts } from '../../../assets/Strings';
import { toast } from 'react-toastify';
import Sounds from '../../../assets/sounds/_exportSounds';
import { checkPhoneNumber } from '../../../RES/user';

const LogIn = ({ fetcher, inAction,}) => {
  const { Language, ...USER } = useContext(TheContext);
  const phone = STRINGS.phone[Language]
  const pass = STRINGS.pass[Language]
  const navigate = useNavigate();
  const user = { [phone]: '', [pass]: "", };

  const RenderTextField = () => {
    const [error, setError] = useState({ [phone]: false, [pass]: false, });
    const fields = Object.keys(user);
    const inputRefs = useRef({});

    const handleNextField = (currentIndex) => {
      const nextIndex = currentIndex + 1;
      if (nextIndex < fields.length) {
        const nextField = fields[nextIndex];
        inputRefs.current[nextField].focus();
      }
    };

    const handleKeyDown = (e, index) => {
      if (e.key === 'Enter') {
        index || e.preventDefault();
        handleNextField(index);
      }
    };

    const handelType = (type) => {
      if (type === pass) {
        return "password"
      } else {
        return "number"
      }
    }

    const testPhoneNumber = (phoneNumber) => {
      phoneNumber.length > 8 && checkPhoneNumber({ phoneNumber }).then((v) => {
        console.log(v);
        
        if (!v?.success) {
          // v[phone]=true
          setError((v) => {
            v[phone] = true
            return { ...v }
          })
          const audio = new Audio(Sounds.error());
          audio.play();
          toast.error(`${STRINGS.phoneNumber_ist_exist[Language]}❌`, { autoClose: false, });
        } else {
          setError((v) => {
            v[phone] = false
            return { ...v }
          })
        }
      });
    }

    return (
      <div className='text-field-box'>
        {fields.map((field, index) => (
          <TextField
            placeholder={field === phone ? '05 * * * * * * *' : ''}
            key={index}
            style={style.TextField}
            name={field}
            label={inAction ? "********" : field}
            type={handelType(field)}
            disabled={inAction}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputRef={(ref) => (inputRefs.current[field] = ref)}
            onBlur={(v) => { field === phone && testPhoneNumber(v.target.value) }}
            error={error[field]}
          />
        ))}
      </div>
    )
  }

  const handleLogin = () => {
    //success/logInFailed/requirement
    if (fetcher?.data === "success") {
      let reUser = getUserFromStorage();
      USER.setUser(reUser);
      navigateMainRout(reUser, navigate);
    } else if (typeof fetcher.data === "string" && !inAction) {
      const audio = new Audio(Sounds.error());
      audio.play();
      toast.error(Alerts?.[fetcher.data]?.[Language]||"error", { autoClose: 1500, });
      fetcher.data = 0
    }
  }

  useEffect(() => {
    handleLogin();// eslint-disable-next-line
  }, [inAction,]);//WARNING//#2999

  return (
    <Container component="main" maxWidth="xs">
      {/* <fetcher.Form method='post'> */}
      <input type='hidden' name='phone' value={phone} />
      <input type='hidden' name='pass' value={pass} />
      <RenderTextField />
      {/* </fetcher.Form> */}
    </Container>
  );
};

export default LogIn;

const style = {
  TextField: {
    marginTop: "20px",
  },
}