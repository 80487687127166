import { defer } from "react-router-dom";
import { getAllUsersData, getAllUsersNames, getWorkingHourTableData } from "../RES/API";
import { getCustomerWithTheProject } from "../RES/customer&Project";
import { restoreUserAPI, restoreWithProjectsAPI } from "../Storage/restoreData";

export async function reloadUser() {
    console.log("~its load for user~");
    return defer(await restoreUserAPI() || {});
}

export async function customersLoader() {
    console.log("its load customers");
    let data = await restoreWithProjectsAPI();
    return defer({
        customers: data,
    });
}

export async function userCustomerLoader() {
    // console.log("~its load for worker~");
    const storedUserAPI = await restoreUserAPI()
    const body = { _id: storedUserAPI.level.customer };
    const storedCustomerAPI = await getCustomerWithTheProject(body);
    storedUserAPI.customerData = storedCustomerAPI.data;
    // get deration
    return { storedUserAPI };
}

export async function LoadWorkingHourSelectData() {
    console.log("~load~");
    const workingHourSelectData = await getWorkingHourTableData()
    console.log("✅",workingHourSelectData.data);
    return {
        Data: workingHourSelectData.data
    };
}

export async function LoadUsersNames() {
    console.log("~load~");
    return defer({ usersNames: await getAllUsersNames() });
}

export async function LoadUsersData() {
    console.log("~load~");
    return defer({ usersData: await getAllUsersData() });
}