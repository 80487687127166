import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import './project.css'
import React from 'react';
import { useLocation } from 'react-router-dom';

const MyComponent = ({ data }) => {
    const RenderTableHead = () => (
      <TableRow>
        {["Check In", "Check Out", "Worker","Duration"].map((name, i) => (
          <TableCell sx={styles.x} key={i}>
            {name}
          </TableCell>
        ))}
      </TableRow>
    );
  
    return (
      <div style={{ margin: '20px' }}>
        <Paper elevation={3} style={{ marginBottom: '20px', padding: '10px' }}>
          <TableContainer>
            <Table sx={{ minWidth: '100%' }} aria-label="project-data">
              <TableHead>
                <RenderTableHead />
              </TableHead>
              <TableBody>
                {data.map((entry, i) => (
                  <TableRow key={i}>
                    <TableCell>{new Date(entry.checkIn).toLocaleString()}</TableCell>
                    <TableCell>{new Date(entry.checkOut).toLocaleString()}</TableCell>
                    <TableCell>{entry.workerName}</TableCell>
                    <TableCell>{entry.duration} hours</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  };
  

const ProjectInfo = () => {
    const {state}=useLocation();
    // console.log(state);
    const {name,shifts}=state.data

    console.log("########",shifts);

    return (
        <div className='page'>
            <Typography variant="h6" gutterBottom>
                Project Information
            </Typography>
            <Typography variant="h6" gutterBottom>
                for {name}
            </Typography>
            <MyComponent data={shifts}/>
        </div>
    );
}

export default ProjectInfo;

const styles ={
    x:{backgroundColor: '#1999',}
}