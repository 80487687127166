import {Close} from '@mui/icons-material';
import React from 'react';

const XButton = ({onClick}) => {
    return (
        <div 
            onClick={(e)=>onClick?.(e)} 
            className='remove'
        >
            <Close/>
        </div>
    );
}

export default XButton;
