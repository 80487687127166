import { fetchApi, stringify } from "./API";

export const loginUser = async (body) => {
    const route = "/loginUser";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const checkPhoneNumber = async (body) => {
    const route = "/checkPhoneNumber";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const createUser = async (body) => {
    const route = "/createUser";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const createUserWoCuSt = async (body) => {
    const route = "/createUserWoCuSt";
    return await fetchApi(route , 'POST' , stringify(body))
}

export const addShiftToUser = async (body) => {
    const route = "/addShiftToUser";
    return await fetchApi(route , 'PATCH' , stringify(body))
}

export const workingHourCAUD = async (
    userUpdated,keyMonth,keyDay,shiftIndex,toDelete,
) => {
    const {name,_id}=userUpdated;
    const shiftDayArray=userUpdated.level.worker.workingHour[keyMonth][keyDay];
    const lengthShiftDayArray=shiftDayArray.length
    const getShiftIndex=(shiftIndex===-1)?lengthShiftDayArray-1:shiftIndex
    const {_id:shiftID,...shiftData}=userUpdated.level.worker.workingHour[keyMonth][keyDay][getShiftIndex]
    console.log("shiftDayArray",shiftData,getShiftIndex,shiftID);
    const bodyWorkingHour ={
        "dateKey":keyMonth,
        "dayKey":JSON.stringify(keyDay),
        "updatedShiftData":
        {
            ...shiftData,
            "worker":{"name":name,"id":_id,"shift_id":shiftID},
        },
        "toDelete":toDelete
    }
    const route = "/workingHourCAUD";
    return await fetchApi(route , 'PATCH' , stringify(bodyWorkingHour))
}

export const shiftAUD = async (
    userUpdated,keyMonth,keyDay,shiftIndex,toDelete,
) => {
    const {_id}=userUpdated;
    const shiftDayArray=userUpdated.level.worker.workingHour[keyMonth][keyDay];
    const lengthShiftDayArray=shiftDayArray.length
    const getShiftIndex=(shiftIndex===-1)?lengthShiftDayArray-1:shiftIndex
    const {_id:shiftID,...shiftData}=userUpdated.level.worker.workingHour[keyMonth][keyDay][getShiftIndex]
    console.log("shiftDayArray",shiftData,getShiftIndex,shiftID);
    const bodyShift ={
        "shiftID":shiftID,
        "dateKey":keyMonth,
        "dayKey":JSON.stringify(keyDay),
        "worker": _id,
        "shiftData":
        {
            ...shiftData,
            customer:shiftData.customer._id,
            project:shiftData.project._id,
        },
        "toDelete":toDelete
    }
    const route = "/shiftAUD";
    // console.log("###############################################");
    // console.log(bodyShift);
    return await fetchApi(route , 'POST' , stringify(bodyShift))
}

export const shiftAUD_V2 = async (
    userId,shift,keyMonth,keyDay,toDelete,
) => {
    let groupId = localStorage.getItem("group-id");
    let shiftID = shift._id
    const bodyShift ={
        "dateKey":keyMonth,
        "dayKey":JSON.stringify(keyDay),
        "worker": userId,
        "shiftData":
        {
                ...shift,
                customer:shift?.customer?._id,
                project:shift?.project?._id,
                "group-id":groupId
            },
        "toDelete":toDelete,
        "shiftID":shiftID||null
    }
    // console.log("🔴🔴🔴🔴🔴");
    // console.log("shiftID",userId,shift,keyMonth,keyDay,toDelete,);
    // console.log("🔴🔴🔴🔴🔴");
    const route = "/shiftAUD";
    return await fetchApi(route , 'POST' , stringify(bodyShift))
    // return "test"
}

export const updateUser = async (body) => {
    const route = "/updateUser";
    console.log("body before updateUser",body);
    return await fetchApi(route , 'PATCH' , stringify(body))
}

export const deleteUser = async (body) => {
    const route = "/deleteUser";
    return await fetchApi(route , 'DELETE' , stringify(body))
}

export const filterUsers = async (body) => {
    const route = "/filterUsers";
    return await fetchApi(route,'POST',stringify(body))
}

export const createGroup_manger = async (body) => {
    const route = "/createGroup_manger";
    return await fetchApi(route , 'POST' , body)
}