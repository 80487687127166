import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';
import { Card, Text, Title } from '../../../elements/MI-elements';
import Filter from '../../../components/Filter';
import { deleteUser, filterUsers } from '../../../RES/user';
import Search from '../../../components/Search';
import { Button, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import TheContext from '../../../Storage/theContext';
import STRINGS from '../../../assets/Strings';
import TheModal from '../../../components/TheModal';
import FieldInput from '../../../components/FieldInput';
import LottieAnimation from '../../../assets/animation/animation';
import { sleep } from '../../actions';

//

const RenderUsers = ({ usersData }) => {
    const { Language } = useContext(TheContext);
    const [model, setModel] = useState(false);
    let fetcher = useFetcher();
    let inAction = fetcher.formData != null;

    const DeleteUser = (phoneNumber) => {
        toast.promise(
            deleteUser({ phoneNumber }),
            {
                pending: STRINGS.Loading[Language],
                success: `${STRINGS.success[Language]}✅`,
                error: `${STRINGS.failed[Language]}❌`
            }
        )
        sleep().then(v => {
            window.location.reload();
        })
    }

    const DeleteFace = ({ user }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Title>{STRINGS.are_you_sure[Language]}</Title>
                <IconButton onClick={() => DeleteUser(user.phoneNumber)}>
                    <Delete sx={{ height: "100%", width: '100%' }} />
                </IconButton>
            </div>
        )
    }

    const UserInfoEdit = ({ user, }) => {
        const userBody = {
            name: {
                value: user.name,
                text: STRINGS.name[Language],
            },
            phoneNumber: {
                value: user.phoneNumber,
                text: STRINGS.phone[Language],
            },
            password: {
                value: user.password,
                text: STRINGS.pass[Language],
            }
        }
        const RenderTextField = () => {
            const fields = Object.entries(userBody);
            const inputRefs = useRef(fields.reduce((acc, [field]) => {
                acc[field] = React.createRef();
                return acc;
            }, {}));

            const handleNextField = (currentIndex) => {
                const nextIndex = currentIndex + 1;
                if (nextIndex < fields.length) {
                    const nextField = fields[nextIndex][0];
                    inputRefs.current[nextField].current.focus();
                }
            };

            return (
                fields.map(([key, { value, text }], index) =>
                    <div key={index} className='continuer-detail'>
                        <div className='detail'>
                            {text} :
                        </div>
                        <FieldInput
                            // sx={(Edit?styles.fieldInput:null)}
                            key={key}
                            index={index}
                            field={text}
                            data={userBody}
                            type={key}
                            value={value}
                            onNextField={handleNextField}
                            inputRefs={inputRefs}
                            // disabled={!(Edit)}
                            lastIndex={fields.length - 1}
                            inAction={inAction}
                        />
                    </div>
                )
            );
        };

        useEffect(() => {
            if (fetcher.data === "updated") {
                window.location.reload();
            }
        }, []);

        return (
            <div style={style.modalContent}>
                <Title>{user.name}</Title>
                <fetcher.Form method="post">
                    <RenderTextField />
                    <input type='hidden' value={JSON.stringify(user)} name='old-user' />
                    <Button color='secondary' type='submit' variant="contained" >
                        {inAction ? STRINGS.saving[Language] : STRINGS.Save[Language]}
                    </Button>
                </fetcher.Form>
                {inAction ? <LottieAnimation data={"loadSticks"} loop={true} /> : null}
            </div>
        )
    }

    return (
        <div className='row' style={{ justifyContent: 'center', }}>
            {usersData.map((user, i) => (
                <Card key={i} style={{ margin: "10px", padding: "5px" }}>
                    <Text style={{ margin: 0 }}>{user.name}</Text>
                    <Text style={{ margin: 0 }}>{user.phoneNumber}</Text>
                    <Text style={{ margin: 0 }}>
                        {user?._level === "worker" ? "worker" :
                            user?._level === "customer" ? "customer" : 'st'}
                    </Text>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <IconButton onClick={() => setModel({ ...user })}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => setModel({ ...user, Delete: "Delete" })}>
                            <Delete />
                        </IconButton>
                    </div>
                </Card>
            ))}
            <TheModal visible={!!model} setVisible={setModel} title={model.name} >
                {model.Delete ?
                    <DeleteFace user={model} />
                    :
                    <UserInfoEdit user={model} />
                }
            </TheModal>
        </div>
    )
}

const Users = () => {
    const { Language } = useContext(TheContext);
    let { usersData } = useLoaderData();
    const [UsersData, setUsersData] = useState(usersData.data);
    const [filter, setFilter] = useState("");

    const FilterUsersApi = () => {
        toast.promise(
            filterUsersPromise(),
            {
                pending: STRINGS.Loading[Language],
                success: `${STRINGS.success[Language]}✅`,
                error: `${STRINGS.failed[Language]}❌`
            }
        )
    }

    const filterUsersPromise = () => (filterUsers({ filter: filter }).then((respond) => {
        let filterData = respond.data;
        // console.log(filterData);
        filterData.length ? setUsersData(respond.data) : setUsersData(usersData.data)
    }).catch((e) => {
        alert("Something went wrong:", e.message)
        setUsersData(UsersData)
    })
    )

    const getUsersNames = () => {
        return UsersData?.map((user) => user.name) || [""]
    }

    const DisplayFilters = () => {
        return (
            <div className='row' style={style.filtersContainer}>
                <Filter
                    filter={filter}
                    setFilter={setFilter}
                    label={"filter users"}
                    options={["worker", "customer", "student",]}
                    style={style.filters}
                />
                {/* fix //new#2999 */}
                {/* <Search
                    Data={UsersData}
                    setData={setUsersData}
                    label={"Search"}
                    options={getUsersNames()}
                    oldData={usersData.data}
                    style={style.filters}
                /> */}
            </div>
        )
    }

    useEffect(() => {
        FilterUsersApi()
    }, [filter]);

    return (
        <div className='column'>
            <Title>Users</Title>
            <DisplayFilters />
            <RenderUsers usersData={UsersData} />
        </div>
    );
}

export default Users;

const style = {
    filtersContainer: {
        width: "100dvw",
        alignSelf: 'center',
        justifyContent: 'space-around',
    },
    filters: {
        maxWidth: "600px",
        minWidth: "200px",
        alignSelf: 'stretch'
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}