import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const Filter = ({ filter, setFilter, label, options, fullWidth, style }) => {
    const selectedOption = filter || null
    const [inputValue, setInputValue] = useState(filter || '');

    const getUniqueOptions = () => {//2999 to filter it in server
        const uniqueOptions = options?.filter((obj, index, self) => {
            return obj.name ? self.findIndex((o) => o.name === obj.name) === index : options
        });
        return uniqueOptions
    }

    const filterOptions = (options, { inputValue }) => {
        return options?.filter(option => {
            const res = option.name ?
                option.name.toLowerCase().includes(inputValue.toLowerCase()) :
                option.toLowerCase().includes(inputValue.toLowerCase())
            return res
        });
    };

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setFilter(newValue);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const renderInput = (params) => {
        return (
            <TextField {...params} label={label} />
        )
    }

    const isOptionEqualToValue = (option, value) => {
        const res = option.name ?
            (option.name === value.name) :
            (option === value)
        return res
    }

    return (
        <Autocomplete
            value={selectedOption}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={getUniqueOptions()}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={filterOptions}
            renderInput={(params) => renderInput(params)}
            fullWidth={fullWidth}
            style={style}
        />
    );
};

export default Filter;
