import React, { useContext, useEffect, useState } from 'react';
import {Box, Autocomplete, TextField } from '@mui/material';
import TheContext from '../Storage/theContext';
import { getUserFromStorage } from '../Storage/restoreData';
import STRINGS from '../assets/Strings';
import { getWorkingHourDates } from '../RES/API';

const YearMonthPicker = () => {
  const {DateStats,Language} = useContext(TheContext);
  const {date,setDate}=DateStats.keyMonth;
  const [selectedDate, setSelectedDate] = useState(date);
  const [inputDate, setInputDate] = useState(date);
  const user = {...getUserFromStorage()};
  const [userWorkingHour, setUserWorkingHour] = useState([date]);

  const getDates=()=>{
    getWorkingHourDates().then((res) => {
      if (res.success) {
        setUserWorkingHour([...res.data]);
      }
    }).catch((error) => {
      console.error("fetch Error", error?.message);
    })
  }

  useEffect(() => {
    getDates();
  }, [])
  

  // if (user?.level.worker?.workingHour) {
  //   userWorkingHour = Object.keys(user.level?.worker?.workingHour);
  // }

  const onChange = (newValue) => {
    if (newValue) {
      setSelectedDate(newValue);
      setDate(newValue)
    }
  };

  const onInputChange = (newValue) => {
    setInputDate(newValue);
  };

  const filterOptions = (options, { inputValue }) => { 
    return options?.filter(option =>{
      return option.toLowerCase().includes(inputValue.toLowerCase())
    });
  };

  const renderInput =(params)=>{
    return(
        <TextField {...params} label={STRINGS.SelectDate[Language]}/>
    )
  }

  return (
    <Box sx={styles.box}>
      <Autocomplete
        value={selectedDate}
        onChange={(_,newValue)=>onChange(newValue)}
        inputValue={inputDate}
        onInputChange={(_,newValue)=>onInputChange(newValue)}
        options={[...userWorkingHour]}
        getOptionLabel={(option)=>option}
        filterOptions={filterOptions}
        renderInput={(params)=>renderInput(params)}
      />
    </Box>
  );
};

export default YearMonthPicker;

const styles={
  box:{
    display:'flex',
    paddingTop:1,
    flexDirection:'column',
    paddingInline:'20%'
  },
  textSize:{fontSize: "12px",},
}