import {useContext, useEffect,} from 'react'
import {Outlet,useFetchers,useLoaderData,useNavigation,useRevalidator} from 'react-router-dom';
import {Content,Page} from '../elements/MI-elements';
import TheContext from '../Storage/theContext';
import HeaderElement from '../elements/Header';
import {saveUserStorage} from '../Storage/restoreData';
import {Alert, LinearProgress,} from '@mui/material';
import {Alerts} from '../assets/Strings';

const Cover = () => {
  const {setGroup,setUser,Language} = useContext(TheContext);
  let revalidator = useRevalidator();
  let navigation = useNavigation();
  let data = useLoaderData();
  let fetchers = useFetchers();
  let fetcherInProgress = fetchers.some((f) =>
  ["loading", "submitting"].includes(f.state)
  );

  const reSetUser=async ()=>{
    const userData=await data;
    saveUserStorage(userData,setGroup)
    setUser(userData);
  }

  const LinearNavigation=()=>{
    return(
      navigation.state !== "idle" &&
      <div style={styles.LinearNavigation}>
            <LinearProgress color="success"/>
      </div>
    )
  }

  const AlertsActions=()=>{
    return(
      <div style={styles.alerts}>
        {navigation.state !== "idle" && 
          <Alert severity='info'>
            {Alerts.Navigation[Language]}
          </Alert>
        }
        {revalidator.state !== "idle" && <p>Revalidation in progress...</p>}
        {fetcherInProgress && <p>{fetchers?.[0]?.data||"in progress..."}</p>}
      </div>
    )
  }

  useEffect(() => {
    reSetUser()// eslint-disable-next-line
  }, []);//WARNING//#2999

  return (
    <Page>
      <LinearNavigation/>
      <HeaderElement/>
      <Content>
        <Outlet/>
      </Content>
      <AlertsActions/>
    </Page>
  )
}

export default Cover;

const styles={
  alerts:{ 
    position: "fixed", 
    bottom: '5%',
    right: '5%',
    zIndex: 9999,
  },
  LinearNavigation:{
    position: "fixed",
    zIndex: 9999,
    width: '100%',
    backgroundColor: '#fff',
  }
}
