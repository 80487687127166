export const newIntendedDate=(date,year,month,day,h,m,sec,ms)=>{
    const newDate = date?new Date(date):new Date();
    (year+month+day+1)&&newDate.setFullYear(year,month,day);
    (h+1)&&newDate.setHours(h);
    (m+1)&&newDate.setMinutes(m);
    (sec+1)?newDate.setSeconds(sec):newDate.setSeconds(0);
    (ms+1)?newDate.setMilliseconds(ms):newDate.setMilliseconds(0);
    return newDate;
}

export const getMaxTime=(isStart,timePickEnd,nextShift,year,monthIndex,day)=>{
    if (isStart&&timePickEnd) {
      return new Date(timePickEnd);
    }
  if (nextShift?.checkIn) {
    return new Date(nextShift.checkIn);
  }
  else{
    return newIntendedDate(null,year,monthIndex,(day+1),0,0,0,0);
  }
}

export const getMinTime=(isStart,timePickStart,backShift,year,monthIndex,day)=>{
    if ((!isStart)&&timePickStart) {
      return new Date(timePickStart)
    }
  if (backShift?.checkOut) {
    return new Date(backShift.checkOut)
  }
  else{
    return newIntendedDate(null,year,monthIndex,(day-1),23,59,59,999);
  }
}