import React from 'react';
import LottieAnimation from '../assets/animation/animation';
import { Logo } from './MI-elements';

const LoadingLogo = () => {
    return (
        <div style={styles.Container}>
            <LottieAnimation style={styles.AnimationSt} data={"loading"} loop={true}/>
            <Logo/>
        </div>
    );
}

export default LoadingLogo;

const styles = {
    Container:{
        display:"flex",
        flex:1,
        alignItems:'center',
        justifyContent:'center',
        overflow:'hidden',
    },
    AnimationSt:{
        position: 'absolute',
        display:"flex",
        zIndex: '0',
        opacity: '0.4',
        height: "100vw",
        width:"100vw",
    },
}