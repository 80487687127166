import React from 'react';
import { Bounce, Flip, Slide, ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlertV2 = ({ isDark }) => {
    //close Time

    return (
        <ToastContainer
            position="bottom-right"
            autoClose={750}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={isDark ? "dark" : "light"}
            transition={Bounce}
        />
    );
}


export default AlertV2;