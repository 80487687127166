import moment from "moment";
import {
  getAllCustomerWithTheProjects,
  getAllCustomers,
} from "../RES/customer&Project";
import { loginUser,} from "../RES/user";

export const restoreUserLocal = () => {
  if (localStorage.length > 0) {
    const storedUser = localStorage.getItem("user");
    return JSON.parse(storedUser);
  }
  console.log("restore User local");
};

export const restoreUserAPI = async () => {
  // console.log("localStorage : ", localStorage);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (localStorage.length > 0 && storedUser) {
    const body = {
      phoneNumber: storedUser.phoneNumber,
      password: storedUser.password,
    };
    const user = await loginUser(body);
    if (user?.success) {
      const data = user.data;
      saveUserStorage(data);
      return data;
    } else {
      return null;
    }
  }
  return null;
};

export const restoreCustomersAPI = async () => {
  const storedCustomers = await getAllCustomers();
  if (storedCustomers?.success) {
    const data = storedCustomers.data;
    return data;
  } else {
    return null;
  }
};

export const restoreWithProjectsAPI = async () => {
  const storedCustomersWithProjects = await getAllCustomerWithTheProjects();
  if (storedCustomersWithProjects?.success) {
    const data = storedCustomersWithProjects.data;
    return data;
  } else {
    return null;
  }
};

export const restoreUser = (setUser) => {
  if (localStorage.length > 0) {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const body = {
      phoneNumber: storedUser.phoneNumber,
      password: storedUser.password,
    };
    loginUser(body).then((v) => {
      if (v?.success) {
        const data = v.data;
        saveUserStorage(data);
        setUser({ ...data });
        //   alert('Loading success');
      } else {
        alert("error Loading");
      }
    });
  }
  console.log("restore User");
};

export const saveUserStorage = (user,setGroup) => {
  const {"group-id":group,...userData} = user||{"group-id":'',user:{}};
  localStorage.setItem("user", JSON.stringify({ ...userData }));
  localStorage.setItem("group", group||'');
  localStorage.setItem("group-id", group?._id||'');
  localStorage.setItem("logo", group?.image||'');
  setGroup?.(group||{});
};

export const getUserFromStorage = () =>
  JSON.parse(localStorage.getItem("user"));

export const isDarkTheme =()=>(JSON.parse(localStorage.getItem("Dark")));
export const setDarkTheme =(v)=>(localStorage.setItem("Dark",v));
export const isMuted =()=>(JSON.parse(localStorage.getItem("Mute")));
export const setMuted =(v)=>(localStorage.setItem("Mute",v));

export const calculateDuration = (startTime, endTime) => {
  const start = startTime;
  const end = endTime;
  if (!(start && end)) {
    return "0";
  }

  const startMoment = moment(start);
  const endMoment = moment(end);
  if (endMoment.isBefore(startMoment)) {
    endMoment.add(1, "day");
  }

  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();

  // console.log(durationTime);
  const durationTime = parseFloat(hours + minutes / 60).toFixed(2);
  return durationTime;
  // return `${hours} hours ${minutes} minutes`;
};
