import './logIn.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';
import TheContext from '../../../Storage/theContext';
import LottieAnimation from '../../../assets/animation/animation';
import LogIn from './LogIn';
import { Logo } from '../../../elements/MI-elements';
import { restoreUserAPI, saveUserStorage } from '../../../Storage/restoreData';
import { navigateMainRout } from '../../../assets/LocalFunctions/Navigation';
import STRINGS from '../../../assets/Strings';
import MenuLanguage from '../../../elements/MenuLanguage';
import SignUp from './CreateCompany';
import { Button, CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../RES/API';

const LogInPage = () => {
    // console.log('~LogInPage~');
    const { user, setUser, Language } = useContext(TheContext);
    const [image, setImage] = useState(null);
    let JSON_log_state = localStorage.getItem('log-in-state');
    let log_state = JSON_log_state ? JSON.parse(JSON_log_state) : true;
    const [state, setState] = useState(log_state === false ? false : true);
    const navigate = useNavigate();
    let fetcher = useFetcher();
    let inAction = fetcher.formData != null;
    const form = state ? STRINGS.LOG_IN[Language] : STRINGS.SIGN_UP[Language]
    const sign_up = STRINGS.sign_up[Language]
    const Loading = STRINGS.Loading[Language]



    const AnimatedWelcome = () => {
        return (
            <div className={`animationText`}>
                <div className='welcome'>
                    <h2 className='shake'>👋</h2>
                    <h2>{STRINGS.welcome[Language]}</h2>
                    <h2 className='reShake'>👋</h2>
                </div>
            </div>
        )
    }

    const loadUser = useCallback(async () => {//#2999 #useCallback$
        const data = await restoreUserAPI()
        if (data) {
            setUser({ ...data })
            saveUserStorage({ ...data })
            navigateMainRout(data, navigate);
        }
    }, [setUser, navigate])

    const ButtonLogIn = () => {
        return (
            <div style={style.buttonDiv}>
                <Button
                    type='submit'
                    sx={{ margin: '10px' }}
                    variant="contained"
                    color={"primary"}
                >
                    {inAction ? Loading : form}
                </Button>
                <LoadingDiv />
            </div>
        )
    }

    const LoadingDiv = () => {
        return (
            <div style={{ display: 'flex', }}>
                {inAction ? <CircularProgress style={{ alignSelf: 'center' }} /> : null}
            </div>
        )
    }

    const SwitchState = () => {
        setState((v) => !v);
        localStorage.setItem('log-in-state', !state);
    }

    const handleSubmit = async (e) => {
        inAction = true
        if (state) {
            return;
        }
        const formData = new FormData();
        let data = JSON.parse(localStorage.getItem("create"));
        const arrayOfEntries = Object.entries(data);
        const body = {};
        arrayOfEntries.forEach(([key, value]) => {
            body[key] = value.value;
            formData.append(key, value.value);
        });
        if (image) {
            formData.append('image', image);
        }
        try {
            const createGroup_manger = async () => {
                return await fetch(`${baseUrl}/createGroup_manger`, {
                    method: 'POST',
                    body: formData,
                }).then(res => res.json())
                    .catch((error) => {
                        console.error("fetch Error", error.message);
                    });
            }
            toast.promise(
                createGroup_manger(formData),
                {
                    pending: STRINGS.Loading[Language],
                    error: `${STRINGS.failed[Language]}❌`
                }).then((result) => {
                    console.log('result', result);
                    if (result?.success) {
                        toast.success('done created', { autoClose: 1000 });
                        navigate("/", { replace: true });
                        SwitchState()
                    } else {
                        toast.info(result?.message || 'failed', { autoClose: false });
                        console.error('Failed to create user');
                    }
                })
        } catch (error) {
            console.error('Error:', error.message);
            toast.info(error.message, { autoClose: false });
        }
    };

    useEffect(() => {
        loadUser()
    }, [loadUser]);



    return (
        <div className="home-page">
            <LottieAnimation style={style.LoadingDiv} data={"loading"} loop={inAction} />
            <div className="banner">
                <Logo />
            </div>

            <fetcher.Form onSubmit={handleSubmit} className='field' method='post'>
                <Typography sx={{ fontSize: 30, marginRight: "15px", }} component="h2" variant="h4">
                    {form}
                </Typography>
                {
                    state ?
                        !(user?.name) ?
                            <LogIn form={form} fetcher={fetcher} inAction={inAction} /> :
                            <AnimatedWelcome />
                        :
                        <SignUp setImageTest={setImage} inAction={inAction} />
                }
                <ButtonLogIn />
            </fetcher.Form>

            <div className="signUp">
                <Button type='button' onClick={() => SwitchState()}>{!state ? STRINGS.LOG_IN[Language] : sign_up}</Button>
            </div>
            <MenuLanguage
                className={"buttonLanguage"}
                style={{}}
                toRefresh={true}
            />
        </div>
    );
};

const style = {
    buttonDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'stretch',
        marginLeft: "20%",
    },
    LoadingDiv: {
        position: 'absolute',
        width: '100dvw',
        maxWidth: '400px',
        zIndex: '0',
        opacity: '0.4',
        overflow: 'hidden',
        top: '42%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    TextField: {
        marginTop: "20px",
    },
}


export default LogInPage;