import React, { useContext, useState } from 'react';
import TheContext from '../../Storage/theContext';
import { shiftAUD_V2 } from '../../RES/user';
import { TheTheme } from '../../assets/theme';
import XButton from '../XButton';
import STRINGS from '../../assets/Strings';
import { Shift_Data } from '../../assets/globalValues';
import { toast } from 'react-toastify';
import { IconButton, Tooltip } from '@mui/material';
import { Notes } from '@mui/icons-material';

const getTXT = (type, Language, Collection) => {
  return (
    Collection ?
      STRINGS[Collection]?.[type]?.[Language]
      : STRINGS[type][Language] || "err"
  )
}

const ShiftDetails = ({ LOADING, shift, shiftIndex, day, ArrayShiftInDay, AdminReq }) => {
  const { DateStats, Language, allShifts, setAllShifts, setShiftData } = useContext(TheContext);
  const { setIsLoading } = LOADING;
  const keyMonth = DateStats.keyMonth.date;
  const worker = shift?.worker?.name//well be un object

  const Remove = (e, shiftIndex) => {
    setIsLoading(true)
    toast.promise(
      shiftAUD_V2("",shift, "", "", true),
      {
        pending: STRINGS.Loading[Language],
        success: `${STRINGS.success[Language]}✅`,
        error: `${STRINGS.failed[Language]}❌`
      }
    ).then(() => {
      setIsLoading(false);
      allShifts[keyMonth][day].splice(shiftIndex, 1);
      setAllShifts((v) => ({ ...v }))
    })
    // console.log(allShifts[keyMonth][day][shiftIndex]);
    e.stopPropagation();
  }

  const editShift = (e) => {
    if (AdminReq) {
      // navigate to user
    } else {
      setShiftData((v) => ({
        ...v,
        title: `${STRINGS.edit[Language]} ${day}`,
        day: day,
        ModalVisible: true,
        shiftIndex: shiftIndex,
        existingShift: shift,
      }))
      e.stopPropagation();
    }
  }

  const getTime = (date) => {//@2999
    const newDate = new Date(date);
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    return (date ? `${hours}:${minutes}` : '-')
  }

  const RenderShiftData = () => {

    const displayDetailType = (type, name) => {
      if (type === "time") {
        let check = shift[name] ? new Date(shift[name]) : null;
        return getTime(check);
      }
      else if (type === "customer-project") {
        let SectionName = shift[name]?.name
        return SectionName;
      }
      else if (type === "dates") {
        let dates = new Date(shift[name]).toLocaleDateString();
        return dates;
      }
      else {
        return name === 'duration' ? shift[name] : (shift[name] ? "✅" : "❌")
      }
    }

    const conditionDisplay = (condition) => {
      return condition ? (AdminReq ? true : false) : true
    }

    return (
      <div className={'UserShiftDetails'}>
        {!AdminReq ? null : <DivShiftDetails>{getTXT(worker, Language, "ShiftData")}{worker || "error name"}</DivShiftDetails>}
        {Shift_Data.map(({ type, Group, AdminReq }, index) => (
          conditionDisplay(AdminReq) ?
            <div className='detailsGroup' key={index}>
              {Group.map((Detail, i) => (
                conditionDisplay(Detail.AdminReq) ?
                  <DivShiftDetails
                    key={i}
                    id={Detail.option ? '' : 'ShiftOneDetail'}
                    class={Detail.option ? Detail.option(shift.checkOut) : ''}
                  >
                    {getTXT(Detail.name, Language, "ShiftData")} : {displayDetailType(type, Detail.name)}
                  </DivShiftDetails> : null
              ))}
            </div> : null
        ))}
        <Tooltip title={shift.note?<h2 style={style.noteStyle}>{shift.note}</h2>:""}>
          <IconButton>
            <Notes/>
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <div onClick={(e) => editShift(e)} className='ShiftBox' style={style.ShiftBox}>
      <div style={style.shiftDetails}>
        {AdminReq ? null : <XButton onClick={(e) => Remove(e, shiftIndex)} />}
        <RenderShiftData />
      </div>
      <div className={shift.checkOut ? '' : 'ShiftNotFinish'} />
    </div>
  )
}

export default ShiftDetails;

const DivShiftDetails = (props) => {
  return (
    <div style={style.DivShiftDetails} id='ShiftOneDetail' className={props.class}>{props.children}</div>
  )
}

const style = {
  DivShiftDetails: {
    backgroundColor: TheTheme.Details,
  },
  ShiftBox: {
    backgroundColor: TheTheme.ShiftBox,
  },
  shiftDetails: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    paddingInline: '5px',
  },
  noteStyle:{
    color:"white",
    whiteSpace: 'pre-line',
    textAlign:'start'
  }
}