import React,{useContext, useState,} from 'react';
import {AppBar,Toolbar,Button,Box,IconButton, Drawer, MenuItem,Menu} from '@mui/material';
import {Link, useNavigate,} from 'react-router-dom';
import TheContext from '../Storage/theContext';
import { Logo } from './MI-elements';
import { AccountCircle, DarkMode, LightMode, Menu as MenuIcon,} from '@mui/icons-material';//Settings
import { getUserFromStorage,isDarkTheme,} from '../Storage/restoreData';
import { TheTheme } from '../assets/theme';
import SidebarContent from './sidebarContent';


const HeaderElement=()=>{
  const {setUser,changeTheme} = useContext(TheContext);
  const navigate =useNavigate();
  const user = getUserFromStorage();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
    
  const LogoTitle=()=>(
    <Box sx={styles.titleBox}>
      <Link unstable_viewTransition to={'/'} replace={true} reloadDocument>
        <Button color='thirds'  sx={styles.titleBox}>
          <Logo h={"100%"}/>
        </Button>
      </Link>
    </Box>
  )

  const reName =(name)=>{//local function
    if (name?.length>10) {
      return (name.slice(0, 6)+"...")
    }else{return(name)}
  }

  const MenuOption=({setAnchorEl,anchorEl,open})=>{
    const user=getUserFromStorage();
    const handleClose = () => {
      setAnchorEl(null);
    };
    const LogOut = () => {
      localStorage.removeItem('user');
      setUser(null)
      navigate("/",{replace:true})
      window.location.reload();
    };


    return(
      <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={()=>{navigate(`/${user.name}`)}}>Profile</MenuItem>
          <MenuItem onClick={LogOut}>Log out</MenuItem>
      </Menu>
    )
  }

  const UserNavigate=()=>{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };
    
    const NavigateToUserPage=()=>{
      navigate(`/${user.name}`,{unstable_viewTransition:true,})
    }
    return(
      <div>
        <IconButton
          onContextMenu={openMenu}
          onClick={NavigateToUserPage} 
          sx={{borderRadius:'10px'}}
        >
          <AccountCircle/>
          {reName(user?.name)}
        </IconButton>
        <MenuOption anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={open}/>
      </div>
    )
  }

  const MenuButton = () =>{
    // console.log(user);
    return(
      // user?.level?.worker?.admin?
      <IconButton onClick={toggleSidebar}><MenuIcon/></IconButton>
      // :null
    )
  }

  const ThemeTrigger =()=>{
    return(
      <IconButton onClick={changeTheme}>
        {isDarkTheme()?<DarkMode/>:<LightMode/>}
      </IconButton>
    )
  }

  return(
    <>
      <AppBar sx={styles.AppBar} position="sticky">
        <Toolbar sx={styles.Toolbar}>
            <LogoTitle/>
            <UserNavigate/>
            <div className='row'>
              <MenuButton/>
              <ThemeTrigger/>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar}>
        <SidebarContent/>
      </Drawer>
    </>
  ) 
}

const styles={
AppBar:{
  height: "60px",
  width: "100vw",
  borderBottomLeftRadius:"15px",
  borderBottomRightRadius:"15px",
},
Toolbar:{
  justifyContent: 'space-between',flexDirection: 'row-reverse',},
title:{fontSize: 20,},
titleBox:{height: "100%", position: 'absolute', left: '50%', transform: 'translateX(-50%)'},
userRout:{color:TheTheme.text}
}

export default HeaderElement;