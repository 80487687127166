import React, { useContext, useEffect, useRef, useState } from 'react';
import { Title } from '../../../elements/MI-elements';
import { useFetcher, useLoaderData, useNavigate, } from 'react-router-dom';
import TheSelectBox from '../../../components/TheSelectBox';
import { projectsBody, } from "../../../assets/globalValues"
import { Box, Button, } from '@mui/material';
import XButton from '../../../components/XButton';
import FieldInput from '../../../components/FieldInput';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import STRINGS, { Alerts } from '../../../assets/Strings';
import TheContext from '../../../Storage/theContext';
import { toast } from 'react-toastify';
import Sounds from '../../../assets/sounds/_exportSounds';
import { NavigateBefore } from '@mui/icons-material';
import { getUserFromStorage } from '../../../Storage/restoreData';

const AddProject = () => {
    const { Language, } = useContext(TheContext);
    const CustomersAPI = useLoaderData();
    const navigate = useNavigate();
    let fetcher = useFetcher();
    const projectTemplate = projectsBody(Language);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ customer: null, });
    const [projects, setProjects] = useState([{ ...projectTemplate }]);
    let inAction = fetcher.formData != null;
    let user = getUserFromStorage();

    const RenderTextField = ({ data, fieldsInput }) => {
        const fields = Object.keys(fieldsInput);
        const inputRefs = useRef(fields.reduce((acc, field) => {
            acc[field] = React.createRef();
            return acc;
        }, {}));

        const handleNextField = (currentIndex,) => {
            const nextIndex = currentIndex + 1;
            if (nextIndex < fields?.length) {
                const nextField = fields[nextIndex];
                inputRefs.current[nextField].current.focus();
            }
        };

        return (
            fields.map((key, index) =>
                <FieldInput
                    key={key}
                    index={index}
                    data={data}
                    type={key}
                    field={data[key].text}
                    value={data[key].value}
                    onNextField={handleNextField}
                    inputRefs={inputRefs}
                    inAction={inAction}
                    disabledSubmit={true}
                    localData={projects}
                />
            ));
    };

    const RenderProjectInput = () => {
        const removeProject = (index) => {
            projects.splice(index, 1)
            setProjects([...projects])
        }
        const AddProjectButton = () => {
            const addProject = () => {
                projects.push(projectTemplate);
                setProjects([...projects])
            }
            return (
                <Button
                    sx={{ alignSelf: 'end' }}
                    onClick={() => addProject()}
                >
                    {STRINGS.add_project[Language]}
                </Button>
            )
        }
        const DisplayDatePicker = ({ index }) => {
            const errorDareAlert = () => {
                const audio = new Audio(Sounds.error());
                audio.play();
                toast.error(Alerts.validDate[Language], { autoClose: 1500, });
            }
            const onChange = (date, index) => {
                if (date) {
                    let yearDigit = JSON.stringify(date?.year() || 0)?.length;
                    if (yearDigit > 3) {
                        validateDate(date, index)
                    }
                }
            }
            const validateDate = (date, projectIndex) => {
                const project = projects[projectIndex];
                const datePicked = new Date(date);
                if (datePicked > new Date()) {
                    project.deadLine = datePicked;
                    setProjects((v) => [...v])
                    localStorage.setItem(`create`, JSON.stringify(projects));
                } else {
                    errorDareAlert();
                    project.deadLine = null;
                }
            }
            return (
                <DatePicker
                    value={projects[index].deadLine ? dayjs(projects[index].deadLine) : null}
                    onChange={(date) => { onChange(date, index) }}
                    minDate={dayjs()}
                    label={<div>{STRINGS.deadLine[Language]}</div>}
                    format="DD / MM / YYYY"
                    slotProps={{
                        textField: { placeholder: 'DD / MM / YYYY' }
                    }}
                />
            )
        }
        const Render = () => {
            return (
                projects.map((project, i) =>
                    <Box id={i} key={i} sx={styles.projectInput}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
                            {i < 1 ? null : <XButton onClick={() => removeProject(i)} />}
                        </div>
                        <RenderTextField
                            key={i}
                            data={project}
                            fieldsInput={projectTemplate}
                        />
                        <DisplayDatePicker index={i} />
                    </Box>
                ))
        }
        return (
            <>
                <Render />
                <AddProjectButton />
            </>
        )
    }

    const CreateButton = () => {
        return (
            <Button
                {...styles.CreateButton}
                type='submit'
                disabled={inAction}
            >
                {inAction ? STRINGS.creating[Language] : STRINGS.create[Language]}
            </Button>
        )
    }

    const cleanInputs = () => {
        if (fetcher?.data === "success") {
            window.location.reload();
        }
    }

    const getCustomers = async () => {
        const customersData = await CustomersAPI.customers;
        setCustomers(customersData);
    }

    useEffect(() => {
        getCustomers();
        cleanInputs();
    }, [inAction]);

    useEffect(() => {
        let customer_id = selectedOption?.customer?.[0]?._id || null;
        console.log("customers._id", customer_id);
        localStorage.setItem('customer', customer_id);
    }, [selectedOption])

    useEffect(() => {
        localStorage.setItem('create', 'false');
        localStorage.setItem('customer', 'false');
        setProjects([{ ...projectTemplate }]);
        setSelectedOption({ customer: null, });
    }, []);

    return (
        <>
            <div style={{width: "100vw",display: 'flex', justifyContent: 'flex-start',}}>
                <Button
                    startIcon={<NavigateBefore style={{fontSize: "2rem"}}/>}
                    color='inherit'
                    onClick={() => { navigate(`/${user.name}/create`) }}
                />
            </div>
            <fetcher.Form method='post' style={styles.CreatePageContainer}>
                <Title>{STRINGS.add_project[Language]}</Title>
                {STRINGS.select_customer_to_add_a_projects[Language]}
                <TheSelectBox
                    Data={selectedOption}
                    setData={setSelectedOption}
                    options={customers}
                    type="customer"
                    label={STRINGS.customers[Language]}
                    style={{ marginBottom: "20px" }}
                    noMultiple={true}
                />
                <RenderProjectInput />
                <CreateButton />
            </fetcher.Form>
        </>
    );
}

export default AddProject;

const styles = {
    CreateButton: {
        variant: "outlined",
        sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingInline: "10%",
            marginBottom: "20px",
        }
    },
    projectInput: {
        borderBottom: 2,
        borderColor: 'divider',
        marginBottom: "20px",
        display: 'flex',
        flexDirection: 'column',
    },
}