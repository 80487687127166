import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import FieldInput from '../../../components/FieldInput'
import { useFetcher, useNavigate } from 'react-router-dom';
import { getUserFromStorage } from '../../../Storage/restoreData';
import { Levels, userBody, userOption, } from "../../../assets/globalValues"
import STRINGS from '../../../assets/Strings';
import TheContext from '../../../Storage/theContext';
import { Title } from '../../../elements/MI-elements';
import { NavigateNext } from '@mui/icons-material';

const CreateUser = () => {
    const { Language, } = useContext(TheContext);
    const [selectedValue, setSelectedValue] = useState(Levels[2]);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    let fetcher = useFetcher();
    let inAction = fetcher.formData != null;
    const isCustomer = selectedValue.eng === "customer";
    const user_Body = userBody(Language);
    const user_Option = userOption();
    const [user, setUser] = useState({ ...user_Body, ...user_Option });
    user.level = selectedValue;

    const RenderTextField = ({ data, fieldsInput }) => {
        const fields = Object.keys(fieldsInput);
        const inputRefs = useRef(fields.reduce((acc, field) => {
            acc[field] = React.createRef();
            return acc;
        }, {}));

        const handleNextField = (currentIndex,) => {
            const nextIndex = currentIndex + 1;
            if (nextIndex < fields?.length) {
                const nextField = fields[nextIndex];
                inputRefs.current[nextField].current.focus();
            }
        };

        return (
            fields.map((key, index) =>
                <FieldInput
                    key={key}
                    index={index}
                    data={data}
                    type={key}
                    field={data[key].text}
                    value={data[key].value}
                    onNextField={handleNextField}
                    inputRefs={inputRefs}
                    inAction={inAction}
                    disabledSubmit={true}
                    localData={user}
                />
            ));
    };

    const CreateButton = () => {
        return (
            <Button
                {...styles.CreateButton}
                type='submit'
                disabled={inAction}
            >
                {inAction ? STRINGS.creating[Language] : STRINGS.create[Language]}
            </Button>
        )
    }

    const CheckBox = () => {
        const handleCheckbox = (event) => {
            setIsAdmin(event.target.checked);
            user.admin = event.target.checked;
            localStorage.setItem(`create`, JSON.stringify(user));
        };
        const Label = () => (
            <Typography variant="h2" sx={{ fontSize: '100%' }}>
                {STRINGS.is_admin[Language]}
            </Typography>
        )
        const CheckB = () => (
            <Checkbox
                checked={isAdmin}
                onChange={handleCheckbox}
                color='success'
            />
        )
        if (selectedValue["eng"] === 'worker') {
            return (
                <FormControlLabel
                    name='admin'
                    value={isAdmin}
                    labelPlacement='end'
                    label={<Label />}
                    control={<CheckB />}
                />
            )
        }
    }

    const RenderLevelsButtons = () => {

        const selected = (value) => {
            return (
                selectedValue[Language] !== value[Language] ? 'inherit' : "primary"
            )
        }

        const onClick = (level) => {
            setSelectedValue(level);
            user.projects = []
            setUser({ ...user })
        }

        return (
            <div style={styles.tabsDiv}>
                {Levels.map((level, i) =>
                    <Button
                        id='levels-button'
                        key={i}
                        color={selected(level)}
                        onClick={() => onClick(level)}
                    >{level[Language]}</Button>
                )}
                <Button
                    id='levels-button'
                    endIcon={<NavigateNext />}
                    color='inherit'
                    onClick={() => { navigate(`/${user.name}/create/add-project`) }}
                >{STRINGS.projects[Language]}</Button>
            </div>
        )
    }

    const cleanInputs = () => {
        // console.log(fetcher.data);
        // if (fetcher.data==="done created") {
        // }
        setUser({ ...user_Body, ...user_Option })
        // localStorage.setItem("create", "false");
    }

    const linkTo = () => {
        const user = getUserFromStorage()
        navigate(`/${user.name}/create/add-project`)
    }

    useEffect(() => {
        cleanInputs()
    }, [inAction, Language]);

    return (
        <Box sx={styles.CreatePageContainer}>
            <RenderLevelsButtons />
            <Title>
                {STRINGS.create[Language] + " " + selectedValue[Language]}
            </Title>
            <fetcher.Form method='post' style={styles.CreatePageContainer}>
                <RenderTextField
                    data={user}
                    fieldsInput={user_Body}
                />
                <CheckBox />
                <CreateButton />
            </fetcher.Form>
            {isCustomer ?
                <Link onClick={linkTo} >
                    {STRINGS.add_project_for_Existing_customer[Language]}
                </Link> : null}
        </Box>
    );
};

export default CreateUser;

const styles = {
    CreatePageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingInline: "10%"
    },
    selectContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'row',
        marginTop: '15px',
    },
    CreateButton: {
        variant: "outlined",
        sx: { margin: '20px' }
    },
    projectInput: {
        borderBottom: 2,
        borderColor: 'divider',
        marginBottom: "20px",
        display: 'flex',
        flexDirection: 'column',
    },
    tabsDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: "100%",
        alignItems: 'center'
    },
    X: {
        display: 'flex',
        flex: 1,
        justifyContent: 'end',
    },
}