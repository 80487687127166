import { createBrowserRouter } from "react-router-dom";
import Cover from "./Cover.jsx";
import { reloadUser } from "./Loaders.js";
import { UserSaveEdits, loginAction } from "./actions.js";
import { Splash, LogInPage, UserPages } from "./pages/exportPages.jsx";
import { AdminRouts, customerRouts, workerRouts } from "./nestedRoutes.jsx";
const { UserPage } = UserPages;

const TheRouter = createBrowserRouter([
    {
        index: true,
        path: "/",
        element: <Splash />,
    },
    {
        path: "/log-in",
        element: <LogInPage />,
        action: loginAction,
    },
    {
        path: ":user",
        element: <Cover />,
        loader: reloadUser,
        children: [
            {
                index: true,
                element: <UserPage />,
                action: UserSaveEdits,
                loader: reloadUser,
            },
            ...workerRouts,
            ...AdminRouts,
            ...customerRouts,
        ]
    },
    {
        path: "/unavailable",
        element: <div style={{textAlign:'center',fontSize:'30px',marginTop:'40px'}}>in repapering</div>,
    },

]);

export default TheRouter