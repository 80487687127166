import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const Test = ({ Data, setData, type, options, fullWidth, style, label, disabled, noMultiple }) => {
    const selectedOption = Data[type]?.length > 0 ? Data[type] : []

    const getUniqueOptions = () => {//2999 to filter it in server
        // let test=options?'':console.log(type,options,label,Data,);
        let save = Array.isArray(options) ? options : [];
        const uniqueOptions = save?.filter((obj, index, self) => {
            return obj.name ? self.findIndex((o) => o.name === obj.name) === index : options
        });
        return uniqueOptions
    }

    const handleChange = (event, newValues) => {
        let length = newValues.length
        Data[type] =(noMultiple&&length)?[newValues[length-1]]:newValues
        setData({ ...Data })
    };

    const renderInput = (params) => {
        return (
            <TextField {...params} label={label || type} />
        )
    }

    const isOptionEqualToValue = (option, value) => {
        const res = option.name ?
            (option.name === value.name) :
            (option === value)
        return res
    }

    return (
        <Autocomplete
            multiple
            value={selectedOption}
            onChange={handleChange}
            options={getUniqueOptions()}
            getOptionLabel={(option) => option?.name || option}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions
            renderInput={(params) => renderInput(params)}
            fullWidth={fullWidth}
            style={style}
            disabled={disabled}
            readOnly={disabled}
        />
    );
};

export default Test;

