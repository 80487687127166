import React, {useContext, useState} from 'react';
import {Autocomplete,TextField,} from '@mui/material';
import STRINGS from '../../../assets/Strings';
import TheContext from '../../../Storage/theContext';

const SelectBoxCP=({ShiftInfo,setShiftInfo,options,type,setP})=>{
  const {Language,} = useContext(TheContext);
    const name = ShiftInfo.select[type]?.name;
    const selectedOption = name||null
    const [inputValue, setInputValue] = useState(name||'');

  const filterOptions = (options, { inputValue }) => { 
    return options?.filter(option =>{
      if (option.name) {
        const res= option.name?
        option.name.toLowerCase().includes(inputValue.toLowerCase()):
        option.toLowerCase().includes(inputValue.toLowerCase())
        return res
      }
    });
  };

  const handleChange = (event, newValue) => {
    ShiftInfo.select[type]=newValue;
    setShiftInfo({...ShiftInfo});
    if (type===STRINGS.customers[Language]) {
      setP(newValue?.projects||null)
    }
  };

  const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
  };

  const renderInput =(params)=>{
      return(
          <TextField {...params} label={type}/>
      )
  }

  const isOptionEqualToValue=(option,value)=>{
      const res=(option.name === value)
      return res
  }
  
    return (
        <Autocomplete
            disabled={!options}
            value={selectedOption}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={options||[]}
            getOptionLabel={(option) => option?.name||option}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={filterOptions}
            renderInput={(params)=>renderInput(params)}
            sx={{
              width: "30%",
              minWidth: "150px",
              m:"3px",
              border:!options&&'1px solid red',
              borderRadius:"5px"
            }}
        />
    );
  };

  export default SelectBoxCP;