import { createTheme } from "@mui/material";

export const TheTheme ={
    main:'#00A8CC',
    secondary:"#27496D",
    thirds:"#454545",
    forth:"#555555",
    text:'#fff',
    Details:"#00a7cc98",
    shiftCover:"#1a3e64",
    ShiftBox:'#25518b',
}

export const DarkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#00A8CC',
      },
      secondary: {
        main: '#27496D',
      },
      thirds: {
        main: '#27496D',
      },
      text: {
        primary: '#999999',
        secondary: '#959595',
      },
      background: {
        default: "#121212",
        main: '#142850',
        black: '#252525',
        paper: "#041434", 
      },
    },
    components:{
      MuiTextField:{
        defaultProps:{
          sx:{
            "& .MuiOutlinedInput-root": {
              color: "#E0E0E0",
              fontFamily: "Arial",
              fontWeight: "bold",
              // Class for the border around the input field
              "& .MuiOutlinedInput-notchedOutline": {
                // borderColor: "#2A2B4A",
                borderWidth: "2px",
              },
            },
            "& .MuiInputLabel-outlined": {
              // color: "#2A2B4A",
              fontWeight: "bold",
            },
          }
        }
      },
      MuiTabs:{
        defaultProps:{
          sx:{
            display:'flex',
            borderRight:2,
            backgroundColor: '#27A8DC',
            border:'0px',
          }
        }
      },
      MuiTab:{
        defaultProps:{
          sx:{
            fontSize:'1.2vh',
            color:'#E0E0E0',
            borderWidth:'2px',
            backgroundColor: '#156280',
            margin:'5px',
            borderRadius: "5px",
          }
        }
      },
      MuiAutocomplete:{
        defaultProps:{
          sx:{
            margin:'5px',
          }
        }
      },
      MuiButton:{
        defaultProps:{
          // variant:'text'
        }
      },
    },
});


export const LightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#00A8CC',
      },
      secondary: {
        main: '#27496D',
      },
      thirds: {
        main: '#27496D',
      },
      text: {
        primary: '#999999',
        secondary: '#959595',
      },
      background: {
        default: "#121212",
        main: '#142850',
        black: '#252525',
        paper: "#041434", 
      },
    },
    components:{
      MuiTextField:{
        defaultProps:{
          sx:{
            "& .MuiOutlinedInput-root": {
              color: "#2A2B4A",
              fontFamily: "Arial",
              fontWeight: "bold",
              // borderColor: "#2A2B4A",
              // Class for the border around the input field
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2A2B4A",
                borderWidth: "2px",
              },
            },
            "& .MuiInputLabel-outlined": {
              color: "#2A2B4A",
              fontWeight: "bold",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "gray",
              borderColor: "#2A2B4A",
            },
          }
        }
      },
      MuiTabs:{
        defaultProps:{
          sx:{
            display:'flex',
            borderRight:2,
            backgroundColor: '#27A8DC',
            border:'0px',
          }
        }
      },
      MuiTab:{
        defaultProps:{
          sx:{
            fontSize:'1.2vh',
            color:'#2A2B4A',
            fontWeight:'bold',
            borderWidth:'2px',
            backgroundColor: '#156280',
            margin:'5px',
            borderRadius: "5px",
          }
        }
      },
      MuiAutocomplete:{
        defaultProps:{
          sx:{
            // backgroundColor: '#156280',
            borderRadius: "5px",
            margin:'5px',
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              color: '#2A2B4A',
              // backgroundColor: '#1A7AA0',
              paddingInline:'3px',
              borderRadius: "5px",
            }
          }
        }
      },
      MuiButton:{
        defaultProps:{
          // variant:'contained'
        }
      },
      MuiTable:{
        defaultProps:{
          style:{
            backgroundColor:'#2A2B4A'
          },
        }
      },
      MuiAppBar:{
        defaultProps:{
          style:{
            backgroundColor:'#1A7AA0',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6)',
          },
        }
      },
      MuiTableRow:{
        defaultProps:{
          style:{
            backgroundColor:'#00a7cc98',
            color:'white'
          },
        }
      },
    }
});

export const lightThemeStyled = {
  body: '#E0E0E0',
  text: '#2A2B4A',
  buttonBg: '#27A8DC',
  buttonText: '#E0E0E0',
  card:"#1A7AA0"
  //Picton Blue-#27A8DC
  //#1F92C0//#1A7AA0//#156280//#104960 <
  //#47B5E1//#65C2E6//#84CEEB//#A3DAF0 <

  //Space cadet-#2A2B4A text
  //#242540//#1E1F36//#18192B//#121320 <
  //#3E406F//#535593//#6F71AE//#9395C2 >
};

export const darkThemeStyled = {
  body: '#1E1F36',
  text: '#E0E0E0',
  buttonBg: '#27A8DC',
  buttonText: '#E0E0E0',
  card:"#1a3e64"
};
  /*
  main:'#00A8CC',
    secondary:"#27496D",
    thirds:"#454545",
    forth:"#555555",
    text:'#fff',
    Details:"#00a7cc98",
    shiftCover:"#1a3e64",
    ShiftBox:'#25518b',
  */

