import React, { useContext, useState } from "react";
import "./UsersPage.css";
import TheContext from "../../../Storage/theContext";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
import STRINGS from "../../../assets/Strings";
import { Text } from "../../../elements/MI-elements";

const UsersPage = () => {
    const { Language } = useContext(TheContext);
    let { usersData } = useLoaderData();
    const [UsersData, setUsersData] = useState(usersData?.data || []);
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const userTableHead = [
        {
            key: "_level",
            value: STRINGS.level[Language],
        },
        {
            key: "name",
            value: STRINGS.name[Language],
        },
        {
            key: "phoneNumber",
            value: STRINGS.phone[Language],
        },
        {
            key: "_active",
            value: STRINGS.status[Language],
        },
    ]

    const RenderUserTableHead = () => {
        return (
            <TableRow>
                {
                    userTableHead.map((user, index) => (
                        <TableCell sx={styles.tableHed} key={index}>
                            <Text style={{ fontWeight: "bold" }}>{user.value}</Text>
                        </TableCell>
                    ))
                }
            </TableRow>
        )
    }
    const RenderUserTableBody = () => {

        const onCellClick = (user) => {
            console.log("cell", user.name);
            navigate(`/${user.name}`, { unstable_viewTransition: true, state: { user } });
        }
        const onStatusCellClick = (e, user_id) => {
            e.stopPropagation();
            console.log("status", user_id);

        }

        return UsersData.map((user, i) => (
            <TableRow className="user-row" key={i}>
                {
                    userTableHead.map(({ key }, index) => (
                        <TableCell key={index} onClick={() => onCellClick(user)}>
                            {key === "_active" ?
                                <div onClick={(e) => onStatusCellClick(e, user._id)} className={`status ${user[key]}`} /> :
                                <Text
                                    className={user._isAdmin && 'admin'} >
                                    {key === "_level" && user._isAdmin ? "⚜️ Admin " : ''}
                                    {user[key]}
                                </Text>
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        ));
    }

    return (
        <div className="users-page">
            <h2>all users</h2>

            <TableContainer className="TableContainer">
                <Table stickyHeader>
                    <TableHead>
                        <RenderUserTableHead />
                    </TableHead>

                    <TableBody>
                        <RenderUserTableBody />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
};

export default UsersPage;

const styles = {
    tableHed: { backgroundColor: '#1999', fontWeight: 'bold' },
}

