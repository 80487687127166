import React, { useContext } from "react";
import BoxOfArrShiftsInDay from "./BoxOfArrShiftsInDay";
import { TheTheme } from "../../assets/theme";
import { daysValues } from "../../assets/globalValues";
import STRINGS from "../../assets/Strings";
import TheContext from "../../Storage/theContext";

const BoxDaysInMonth = ({ userShift, day, month, year }) => {
  const {Language,} = useContext(TheContext);
  const daysV = daysValues[Language];
  const TODAY = STRINGS.today[Language];
  const ArrayShiftInDay = userShift?.[day];
  const currentDate = new Date().toLocaleDateString();
  const boxDate=new Date(year,(month-1),day).toLocaleDateString();
  const isCurrentDay = boxDate === currentDate;

  const dayInWeek = new Date(year, month - 1, day).getDay();

  const DayOfMonth = () => {
    return (
      <div style={styles.day} id="days">
        <div>{`${day}/${month}`}</div>
        <div>{isCurrentDay ? TODAY : daysV[dayInWeek]}</div>
      </div>
    );
  };

  return (
    <div
      id="DayOfMonth"
      className={isCurrentDay ? "current-day" : ""}
      style={isCurrentDay ? styles.DayOfMonth : styles.todyShift}
    >
      <DayOfMonth />
      <BoxOfArrShiftsInDay day={day} ArrayShiftInDay={ArrayShiftInDay} />
    </div>
  );
};

export default BoxDaysInMonth;

const styles = {
  day: {
    padding: "2px",
    color:"#E0E0E0"
  },
  DayOfMonth: {
    backgroundColor: TheTheme.shiftCover,
  },
  todyShift: {
    backgroundColor: TheTheme.secondary,
  },
};
