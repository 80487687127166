import React, {useEffect, useState } from 'react';
import { Container, Paper, Typography,ListItem, ListItemText, Divider } from '@mui/material';
import { TheTheme } from '../../../assets/theme';
import { useLoaderData, useNavigate } from 'react-router-dom';

const CustomerInfo = () => {
  // const {user} = useContext(TheContext);
  const navigate = useNavigate();
  let {storedUserAPI} = useLoaderData();
  const [customerData, setCustomerData] = useState(null);
  // let customerData=user?.customerData;

  const RenderList=({text,data,dataType,onClick})=>{
    const onClickProject=()=>{
      if(onClick){
        navigate(`/${storedUserAPI.name}/project-info`,{state:{data},})
      }
    }

    const fixText=(text)=>{
      let fixed= text.replace(/_/,'');
      fixed= fixed.replace(/([a-z])([A-Z])/g, '$1 $2');
      fixed= fixed.charAt(0).toUpperCase() + fixed.slice(1);
      return fixed
    }

    return(
      dataType.map((key,i)=>(
        <ListItem key={i} 
          onClick={()=>onClickProject()}
        >
          <ListItemText primary={`${(text||'')+" "+fixText(key)} : ${data?.[key]}`} />
        </ListItem>
      ))
    )
  }

  useEffect(() => {
    console.log(storedUserAPI);
    setCustomerData(storedUserAPI.customerData)
    // customerData=data.customerData
  }, []);
  
  return (
      <Container style={styles.container}>

        <RenderList data={customerData} dataType={['name','_id','totalSoFar','hour']}/>
  
          <Divider style={{ margin: '20px 0', backgroundColor: TheTheme.forth }} />
  
        <Typography variant="h6">Projects:</Typography>
  
        {customerData?.projects.map((project) => (
          <Paper className='button' key={project._id} elevation={3} style={styles.paper}>
              <RenderList
                data={project} 
                dataType={['name','budget','totalSoFar']} 
                text={'Project'} 
                onClick={true}
              />
          </Paper>
        ))} 
        
      </Container>
  );
};

export default CustomerInfo;

const styles = {
  container:{padding: '20px', borderRadius: '10px', color: TheTheme.text },
  paper:{
    backgroundColor:TheTheme.secondary,
    padding: '15px',
    borderRadius: '10px',
    margin:'10PX',
    display:'flex',
    flexDirection:'row'
  }
}
