import { AccountCircle, Create, Feed, ListAlt, People, Work, WorkHistory, } from '@mui/icons-material';
import STRINGS from './Strings';


export const daysValues = {
  eng: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  arabic: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
  hebrew: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'שבת'],
};

export const TabsLabels = [
  {
    name: {
      eng: 'profile',
      arabic: 'الملف الشخصي',
      hebrew: 'פרופיל',
    },
    required:"",
    path: '',
    IconName: () => <AccountCircle />
  },
  {
    name: {
      eng: 'month shifts',
      arabic: 'الورديات الشهرية',
      hebrew: 'משמרות חודשיות',
    },
    path: 'month-shifts',
    required: "worker",
    IconName: () => <Work />
  },
  {
    name: {
      eng: 'Working Hour',
      arabic: 'ساعات العمل',
      hebrew: 'שעות עבודה',
    },
    path: "working-hour",
    required: "worker",
    IconName: () => <WorkHistory />
  },
  {
    name: {
      eng: 'all users',
      arabic: 'جميع المستخدمين',
      hebrew: 'כל המשתמשים',
    },
    path: 'all-users',
    required: "admin",
    IconName: () => <People />
  },
  {
    name: STRINGS.create,
    path: 'create',
    required: "admin",
    IconName: () => <Create />
  },
  {
    name: {
      eng: 'customer info',
      arabic: 'معلومات العميل',
      hebrew: 'פרטי לקוח',
    },
    path: 'customer-info',
    required: "customer",
    IconName: () => <Feed />
  },
];

export const projectTemplate = {
  name: '',
  budget: '',
  hourPrice: '',
  deadLine: '',
}

export const userTemplate = {
  name: '',
  phoneNumber: '',
  password: "",
  level: 'student',
  admin: undefined,
  projects: []
}

export const Shift_Data = [
  {
    type: "time",
    Group: [
      {
        display: (v) => (v),
        name: "checkIn",
      },
      {
        display: (v) => (v),
        name: "checkOut",
        option: (state) => (state ? '' : 'ShiftNotFinish')
      },
    ]
  },
  {
    type: "customer-project",
    Group: [
      {
        display: (v) => (v),
        name: "customer",
      },
      {
        display: (v) => (v),
        name: "project",
      },
    ]
  },
  {
    type: "dates",
    AdminReq: true,
    Group: [
      {
        display: (v) => (v),
        name: "created",
      },
      {
        display: (v) => (v),
        name: "edited",
      },
    ]
  },
  {
    type: "else",
    Group: [
      {
        display: (v) => (v),
        name: "duration",
      },
      {
        display: (v) => (v),
        name: "zoomShift",
        AdminReq: true,
      },
    ]
  }
]

export const LanguageValues = [
  {
    name: "eng",
    type: "eng"
  },
  {
    name: "العربية",
    type: "arabic"
  },
  {
    name: "עברית",
    type: "hebrew"
  },
]

export const dayShift = [
  {
    eng: "day",
    arabic: "يوم",
    hebrew: "יום"
  },
  {
    eng: "shift",
    arabic: "الوردية",
    hebrew: "משמרת"
  }
]

export const Levels = [
  {
    eng: "worker",
    arabic: "عامل",
    hebrew: "עובד"
  },
  {
    eng: "customer",
    arabic: "زبون",
    hebrew: "לקוח"
  },
  {
    eng: "student",
    arabic: "طالب",
    hebrew: "תלמיד"
  }
]

export const projectsBody = (Language) => {
  return (
    {
      name: {
        value: "",
        text: STRINGS.name[Language],
      },
      budget: {
        value: "",
        text: STRINGS.budget[Language],
      },
      HourPrice: {
        value: "",
        text: STRINGS.HourPrice[Language],
      },
    }
  )
}

export const userBody = (Language) => {
  return (
    {
      name: {
        value: "",
        text: STRINGS.name[Language],
      },
      phoneNumber: {
        value: "",
        text: STRINGS.phone[Language],
      },
      password: {
        value: "",
        text: STRINGS.pass[Language],
      },
    }
  )
}

export const userOption = () => {
  return (
    { level: 'student', admin: undefined, projects: [] }
  )
}