import React, { useContext, useRef, useState } from 'react';
import STRINGS from '../../../assets/Strings';
import TheContext from '../../../Storage/theContext';
import FieldInput from '../../../components/FieldInput';
import { Button, Container,} from '@mui/material';
import { Image } from '@mui/icons-material';
import { toast } from 'react-toastify';

const SignUp = ({ inAction,setImageTest }) => {
    const { Language, ...Context } = useContext(TheContext);//Company=>Group

    const companyBody = {
        'name': {
            value: '',
            text: STRINGS.Company_Name[Language],
        },
        "group-manager": {
            value: '',
            text: STRINGS.manager[Language],
        },
        "phone-number": {
            value: '',
            text: STRINGS.phone[Language],
        },
        "password": {
            value: '',
            text: STRINGS.pass[Language],
        },
    }
    const [company, setCompany] = useState({ ...companyBody });
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);



    const ChoseImagePreview = () => {
        const handleImageChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

                if (allowedFormats.includes(file.type)) {
                    setImage(file);
                    setImageTest(file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImagePreview(reader.result);
                        localStorage.setItem(`image-create`, JSON.stringify(reader.result));
                    };
                    reader.readAsDataURL(file);
                } else {
                    toast.error('Please select a valid image file (JPG, PNG, or JPEG)', { autoClose: false, });
                    e.target.value = '';
                    setImagePreview(null);
                    setImage(null);
                }
            }
        };

        // const fileInput = document.getElementById('image-upload');
        // console.log(fileInput?.files?.[0]);

        return (
            <div className='text-field-box'>


                <label htmlFor="image-upload" >
                    <br />
                    <input
                        type="file"
                        accept="image/*"
                        id="image-upload"
                        onChange={handleImageChange}
                        hidden
                    />
                    <Button variant="outlined" component="span">
                        chose
                        <br />
                        image
                    </Button>
                </label>

                {imagePreview ?
                    <img style={style.image} src={imagePreview} alt="Selected" /> :
                    <Image style={style.image} />
                }
            </div>
        )
    }

    const RenderTextField = ({ data, fieldsInput }) => {
        const fields = Object.keys(fieldsInput);
        const inputRefs = useRef(fields.reduce((acc, field) => {
            acc[field] = React.createRef();
            return acc;
        }, {}));

        const handleNextField = (currentIndex,) => {
            const nextIndex = currentIndex + 1;
            if (nextIndex < fields?.length) {
                const nextField = fields[nextIndex];
                inputRefs.current[nextField].current.focus();
            }
        };

        return (
            fields.map((key, index) =>
                <FieldInput
                    key={key}
                    index={index}
                    data={data}
                    type={key}
                    field={data[key].text}
                    value={data[key].value}
                    onNextField={handleNextField}
                    inputRefs={inputRefs}
                    inAction={inAction}
                    disabledSubmit={true}
                    localData={company}
                />
            ));
    };

    useState(() => {
        localStorage.setItem("create", "{}");
    }, []);


    return (
        <Container component="main" maxWidth="xs">
            <div className='text-field-box'>
                <RenderTextField
                    data={company}
                    fieldsInput={companyBody}
                />
            </div>
            <ChoseImagePreview />
        </Container>
    );
}

export default SignUp;

const style = {
    image: {
        width: '150px',
        height: '150px',
        alignSelf: 'center',
        margin: '10px',
    }
}
