import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TheContext from '../../Storage/theContext';
import { restoreUserAPI, saveUserStorage } from '../../Storage/restoreData';
import LoadingLogo from '../../elements/LoadingLogo';
import { PageSize } from '../../elements/MI-elements';
import { navigateMainRout } from '../../assets/LocalFunctions/Navigation';

const Splash = () => {
    const {setUser} = useContext(TheContext);
    const navigate = useNavigate();

    const loadUser = useCallback(async ()=>{ 
        const data = await restoreUserAPI()
        if (data&&data.password&&data.phoneNumber) {
            setUser({...data});
            saveUserStorage({...data})
            navigateMainRout(data,navigate);
        }else{
            navigate("/log-in",{replace:true})
        }
    },[setUser,navigate])

    const timeoutLoad = () => {
        console.log("time out");
        localStorage.removeItem('user');
        setUser(null)
        navigate("/log-in",{replace:true})
        window.location.reload();
    };
    
    useEffect(() => {
        loadUser()
        const timeout = setTimeout(timeoutLoad, 25000);
        return () => clearTimeout(timeout);// eslint-disable-next-line
    }, [loadUser,]);//WARNING//#2999

    return (<PageSize><LoadingLogo/></PageSize>);
}

export default Splash;