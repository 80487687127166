import React, {useState,} from 'react';
import { TextField } from "@mui/material";

const FieldInput = ({
  type,field,data,value, onNextField,
  inputRefs,index,disabled,setData,lastIndex,
  inAction,disabledSubmit,localData
}) => {
    const [text, setText] = useState(value);
    const inputRef = inputRefs.current[type];

    const onChange=(value)=>{
      data[type].value=value;
      setText(value);
      localData&&localStorage.setItem(`create`,JSON.stringify(localData));
    }

    const typeOf=()=>{      
      if(type==="password"){
        return("password")
      }
      else if(type.includes("phone")||type.includes("number")||type==="phoneNumber"||type==="budget"||type==="HourPrice"){
        return("number")
      }
      else{return("text")}
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        onNextField?.(index,);
        if (!disabledSubmit) {
          index===lastIndex||e.preventDefault();
        }else{
          e.preventDefault();
          // setUser
        }
      }
    };// to go to next field on enter
 
    return (
      <TextField
        name={type}
        placeholder={type==="phoneNumber"?'05 * * * * * * *':''}
        variant="outlined"  
        margin="normal"
        label={field}
        type={typeOf()}
        value={inAction?"********":text}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        disabled={disabled||inAction||false}
        onBlur={()=>setData?.({...data})}
      />
    );
  };

  export default FieldInput