import excel from "./excel.jpg"
import logo from "./logoZone.png"
// import logoTime from "./logoTime.png"

export const ImportImages = (name) => {
    try {
        let groupLogo = localStorage.getItem("logo")        
        const Images = {
            excel: () => excel,
            logo: () => groupLogo?groupLogo:logo,
        }
        return Images[name]?.() || null
    }
    catch (e) {
        return null;
    }
}