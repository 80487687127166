import { toast } from "react-toastify";
import { addManyProjects, } from "../RES/customer&Project";
import { createUserWoCuSt, loginUser, updateUser } from "../RES/user";
import { saveUserStorage } from "../Storage/restoreData";

export async function UserSaveEdits({ request }) {
    let formData = await request.formData();
    let oldUser = JSON.parse(formData.get("old-user"));
    let name = formData.get("name") || oldUser.name;
    let phoneNumber = formData.get("phoneNumber") || oldUser.phoneNumber;
    let password = formData.get("password") || oldUser.password;
    let isUser = formData.get("user")
    console.log(name, phoneNumber, password, oldUser.phoneNumber);
    const body = {
        phoneNumber: oldUser.phoneNumber,
        updatedData: { name, phoneNumber, password, }
    }
    // console.log("⚙️⚙️⚙️⚙️",body);
    if (body) {
        return await (
            updateUser(body).then((v) => {// 2999 update
                if (v?.success) {
                    isUser ? saveUserStorage(v.data) : window.location.href = '/user/all-users';
                    return "updated"
                } else {
                    return "failed"
                }
            })
        )
    } else {
        return "Something went wrong";
    }
}

const validation = (user) => {
    return (
        user?.name?.value?.length > 2 &&
        user?.phoneNumber?.value?.length > 8 &&
        user?.password?.value?.length > 5 &&
        user?.level?.eng
    )
};

const createBody = (user) => {
    const { name, phoneNumber, password, level } = user;
    const isCustomer = (level.eng === "customer");
    const group_id = localStorage.getItem("group-id");
    const body = {
        name: name.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        _level: level.eng,
        _isAdmin: user.admin,
        'group-id': group_id,
    }
    if (isCustomer) {
        body.projects = []
    }
    return body;
}

const startCreate = async (body) => {
    console.log(body);

    return await createUserWoCuSt(body).then((v) => {
        if (v?.success) {
            console.log(v);
            // alert("done created")
            return "done created"
        } else {
            alert(v.message)
            return (v.message)
        }
    })
};

export async function createUsers({ request }) {
    await request.formData();
    let user = JSON.parse(localStorage.getItem("create"));
    try {
        const body = createBody(user)
        return await startCreate(body).then((v) => {
            toast.success(v, { autoClose: false })
            return v
        }) || "...";
    }
    catch {
        toast.error('validation failed', { autoClose: false });
        return '...'
    }
    // return "...test"
}

const logInSuccess = async (res) => {//success#2999
    if (res?.success) {
        saveUserStorage(res.data);
        return "success"
    } else {
        return "logInFailed"
    }
}

export async function loginAction({ request }) {
    let JSON_log_state = localStorage.getItem('log-in-state');
    let log_state = JSON_log_state ? JSON.parse(JSON_log_state) : true;
    let formData = await request.formData();
    if (log_state) {
        let phoneNumber = formData.get("phone")
        let password = formData.get("pass")
        let phoneNumberValue = formData.get(phoneNumber)
        let passwordValue = formData.get(password)
        let isPhoneNumber = /^\d+$/.test(phoneNumberValue);
        // console.log("phoneNumber:", phoneNumberValue,"password:",passwordValue);
        if (phoneNumberValue && passwordValue && isPhoneNumber) {
            const body = { "phoneNumber": phoneNumberValue, "password": passwordValue }
            return loginUser(body).then((res) => {
                return logInSuccess(res)
            })
        } else {
            return "logIn_requirement"
        }
    }
    else {
        console.log("sign up");
        // signUp()
        // new#2999
        return 'sign up'
    }
    // return 'test'
}

export const signUp = (image) => {
    // let image = formData.get("image");
    let data = JSON.parse(localStorage.getItem("create"));
    const arrayOfEntries = Object.entries(data);
    const body = {};
    body.image = image;
    arrayOfEntries.forEach(([key, value]) => {
        body[key] = value.value;
    });
    console.log("test 1", image);
    // console.log(image);
    return "test";
}

const createProjectsBody = (projects) => {
    return projects
        ? projects
            .filter(project => project.name.value !== '')
            .map(project => ({
                ...Object.fromEntries(Object.entries(project).map(([key, { value }]) => [key, value])),
                ["group-id"]: localStorage.getItem("group-id"),
                customer: localStorage.getItem("customer"),
                customer_id: localStorage.getItem("customer"),
            }))
        : [];
};

export async function AddProjectsAction({ request }) {
    let formData = await request.formData();
    let projects = JSON.parse(localStorage.getItem("create"));
    let projectsBody = createProjectsBody(projects);
    // console.log(localStorage.getItem("customer"));
    if (projects.length > 0) {
        return addManyProjects(projectsBody).then(async (res) => {
            console.log("res", res);
            if (res.success) {
                toast.success("done created");
                await sleep();
                return "success"
            } else {
                toast.error(res.message, { autoClose: false });
                return "loading..."
            }
        }).catch((err) => {
            console.log("❌", err);
            return err.message;
        })
    } else {
        return 'missing projects'
    }
}

export async function testAction({ request }) {
    // await sleep()
    let formData = await request.formData();
    console.log(formData);
    return 'test'
}

export function sleep() {
    return new Promise((r) => setTimeout(r, 500));
}