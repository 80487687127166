import { utils, writeFile } from "xlsx";

const handelData=(Data)=>{
    const newJsonData=[];
    Data.forEach((arrayShift)=>{
        arrayShift.forEach(({checkIn,customer,duration,project,zoomShift,worker})=>{
            const NewDate = new Date(checkIn);
            const dayName= NewDate.toLocaleDateString("default", { weekday: 'long' });
            const newShift={
                day: dayName,
                date: NewDate.toLocaleDateString(),
                duration: duration,
                customer: customer?.name||"error name",
                project: project?.name||"error name",
                zoomShift,
                employed:worker?.name||"error name",
            }
            newJsonData.push(newShift)
        })
    })
    return newJsonData;
}

export const JsonToExcelButton = (data,PairHour,selectedSection) => {
    if (!(PairHour||data)) {
        return false
    }
    const jsonData=handelData(Object.values(data))
    const names = Object.values(selectedSection).map((items)=>items?.map((item)=>item?.name||''));
    const text = names.toString().replace(",","-").replace(",,","-").replace("--","")+"."

    const title = `${text}data.xlsx`
    // console.log("title",title);

    const worksheet = utils.json_to_sheet(jsonData, {
        header: ["day", "date", "duration", "customer", "project","zoomShift","employed",],
    });

    const sumFormula = `SUM(C2:C${jsonData.length + 1})`;
    const sectionsLine = jsonData.length+3

    const sections = [
        {"PairHour":{t: "n",v:PairHour}},
        {"totalDuration":{ t: "n", f: sumFormula }},
        {"overall":{ t: "n", f:'' }}
    ]

        sections.forEach((item,i)=>{
            Object.entries(item).forEach(([key,value])=>{
                const index = sectionsLine + i;
                const A = `A${index}`;
                const B = `B${index}`;
            
                worksheet[A] = { t: "s", v: key };
                worksheet[B] = (key==="overall")?{ t: "n", f:`B${index-2}*B${index-1}` }:value
        })
    })
  
      worksheet['!ref']=`A1:H${sectionsLine+sections.length+1}`
      // 20`
      
  
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      writeFile(workbook, title);
}